import React from 'react'
import { Link } from 'react-router-dom'

const Section3 = () => {
  return (
    <>
     <section className="sale section--nopb">
      <div className="container">
        <div className="sale_grid d-grid">
          <div className="sale_header">
            <h2 className="sale_header-title">
              Buy Herbalist Product for Sale Online in 2021
            </h2>
            <p className="sale_header-text">
              Two free samples with any order when you spend $50
            </p>
            <Link className="sale_header-btn btn" to={'/shop'}>
              Shop Now
            </Link>
          </div>
          <div className="sale_grid-item sale_grid-item--pm">
            <div className="sale_grid-item_bg">
              
              <img
                  className="lazy"
                  src="img/about/sale--pm.jpg"
                  alt="media"
                />
            </div>
            <div className="sale_grid-item_content d-flex flex-column justify-content-end">
              <h3 className="sale_grid-item_title">PM Happy Hour</h3>
              <div className="wrapper d-flex flex-column">
                <span className="text">Sale up to 5%</span>
                <Link className="btn--underline" to={'/shop'}>
                  Shop now
                </Link>
              </div>
            </div>
          </div>
          <div className="sale_grid-item sale_grid-item--am">
            <div className="sale_grid-item_bg">
            
              <img
                  className="lazy"
                  src="img/about/sale--am.jpg"
                  alt="media"
                />
            </div>
            <div className="sale_grid-item_content d-flex flex-column justify-content-end">
              <h3 className="sale_grid-item_title">AM Happy Hour</h3>
              <div className="wrapper d-flex flex-column">
                <span className="text">Sale up to 15%</span>{" "}
                <Link className="btn--underline"   to={'/weeklyoffer'}>
                  Shop now
                </Link>
              </div>
            </div>
          </div>
          <div className="sale_grid-item sale_grid-item--medical">
            <div className="sale_grid-item_bg">
              
              <img
                  className="lazy"
                  src="img/about/sale--medical.jpg"
                  alt="media"
                />
            </div>
            <div className="sale_grid-item_content d-flex flex-column justify-content-end">
              <h3 className="sale_grid-item_title">Medical Special</h3>
              <div className="wrapper d-flex flex-column">
                <span className="text">Sale up to 20%</span>{" "}
                <Link className="btn--underline"  to={'/weeklyoffer'}>
                  Shop now
                </Link>
              </div>
            </div>
          </div>
          <div className="sale_grid-item sale_grid-item--weekly">
            <div className="sale_grid-item_bg">
            <img
                  className="lazy"
                  src="img/about/sale--weekly.jpg"
                  alt="media"
                />
            </div>
            <div className="sale_grid-item_content d-flex flex-column justify-content-center">
              <span className="label d-flex align-items-center justify-content-center">
                -30%
              </span>{" "}
              <span className="sale_grid-item_subtitle">
                From shampoos to face masks
              </span>
              <h3 className="sale_grid-item_title">
                Weekly Special <span className="linebreak">CBD Products</span>
              </h3>
              <Link className="btn--underline"  to={'/weeklyoffer'}>
                Shop now
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default Section3