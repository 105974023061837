/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addItem } from "../Redux/Addtochart";
import { addItemInList } from "../Redux/wishlistSlice";
import toast, { Toaster } from "react-hot-toast";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import StarRatings from "react-star-ratings";
const Section1 = () => {
  const { id } = useParams();
  const disptach = useDispatch();
  const [data, setdata] = useState([]);
  // const [thumbsSwiper, setThumbsSwiper] = useState(null);
  useEffect(() => {
    Fatchsingledata();
  }, []);
  const Fatchsingledata = async () => {
    axios
      .get(`https://api.weedowl.dev-iuh.xyz/api/products/${id}`)
      .then((res) => {
        console.log(res, "singledata");
        setdata(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const Addtochart = (data) => {
    console.log(data, "checkout");
    const Datatosend = {
      image: data.media,
      name: data.title,
      price: data.discounted_amount,
      qty: 1,
      id: data.id,
      rating: data.rating,
      weight:data.weight
    };
    disptach(addItem(Datatosend));
    toast.success("Added Successfully!");
  };
  const Addtolist = (data) => {
    const Datatosend = {
      image: data.media,
      name: data.title,
      price: data.discounted_amount,
      id: data.id,
      rating: data.rating,
      weight:data.weight
    };
    disptach(addItemInList(Datatosend));
    toast.success("Added To List Successfully!");
  };
  return (
    <>
      <section className="about mt-3">
        <Toaster position="top-right" reverseOrder={false} />
        <div className="container">
          <div className="about_main d-lg-flex flex-nowrap">
            <div className="about_main-slider">
              <div className="about_main-slider--single">
                <Swiper
                  style={{
                    "--swiper-navigation-color": "black",
                    "--swiper-pagination-color": "black",
                  }}
                  spaceBetween={10}
                  navigation={true}
                  // thumbs={{ swiper: thumbsSwiper }}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper2"
                >
                  {data?.media?.map((data, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <img
                          className="lazy"
                          src={data.media}
                          height={"400px"}
                          alt="media"
                        />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
                <Swiper
                  // onSwiper={setThumbsSwiper}
                  spaceBetween={10}
                  slidesPerView={4}
                  freeMode={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="swiper-wrapper mt-3"
                >
                  {data?.media?.map((data, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <img
                          className="lazy"
                          src={data.media}
                          alt="media"
                          height={"110px"}
                        />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
            <div className="about_main-info">
              <div className="about_main-info_product d-sm-flex align-items-center justify-content-between">
                <h2 className="title">{data.title}</h2>
                <div className="action d-flex">
                  <div
                    className="action_link d-flex align-items-center justify-content-center"
                    data-role="wishlist"
                  >
                    <i className="icon-heart" onClick={() => Addtolist(data)} />
                  </div>
                </div>
              </div>
              <div className="about_main-info_rating d-flex align-items-center">
                <StarRatings
                  rating={data.rating}
                  starRatedColor="#efc368"
                  // changeRating={changeRating}
                  numberOfStars={5}
                  starDimension="30px"
                  name="rating"
                />

                {/* <a className="reviews-amount" href="#reviews">
                  (2 customer reviews)
                </a> */}
              </div>
              <p className="about_main-info_description">{data.description}</p>
              <span className="about_main-info_price">
                ${data.discounted_amount}
              </span>
              <div className="about_main-info_buy m-0 ">
                {/* <div className="qty d-flex align-items-center justify-content-between">
                  <span className="qty_minus control disabled d-flex align-items-center">
                    <i className="icon-minus" />{" "}
                  </span>
                  <input
                    className="qty_amount"
                    type="number"
                    readOnly="readonly"
                    defaultValue={1}
                    min={1}
                    max={99}
                  />
                  <span className="qty_plus control d-flex align-items-center">
                    <i className="icon-plus" />
                  </span>
                </div> */}
                <button className="btn m-0 " onClick={() => Addtochart(data)}>
                  Add to Cart
                </button>
              </div>
              <ul className="about_main-info_list">
                <li className="list-item">
                  <span className="property">Category:</span>
                  <span className="value">{data?.category?.name}</span>
                </li>
                <li className="list-item">
                  <span className="property">weight:</span>{" "}
                  <span className="value">{data.weight} gm</span>
                </li>
                <li className="list-item">
                  <span className="property">Plant Type:</span>{" "}
                  <span className="value">{data.plant_type}</span>
                </li>
                <li className="list-item">
                  <span className="property">THC:</span>{" "}
                  <span className="value">{data.thc}%</span>
                </li>
                <li className="list-item">
                  <span className="property">CBD:</span>{" "}
                  <span className="value">{data.cbd}%</span>
                </li>
                {/* <li className="list-item">
                  <span className="property">Effects:</span>{" "}
                  <span className="value">Calm, Happy, Relaxed</span>
                </li> */}
                {/* <li className="list-item">
                  <span className="property">Tags:</span>{" "}
                  <span className="value">Cannabis, oil</span>
                </li> */}
                {/* <li className="list-item">
                  <span className="property">SKU:</span>{" "}
                  <span className="value">1234567</span>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Section1;
