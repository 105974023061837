import React from "react";
import { Link } from "react-router-dom";

const Product = () => {
  return (
    <>
      <div className="product section">
        <div className="container d-flex flex-column flex-lg-row">
          <div className="product_media">
          <img
                className="lazy"
                src="img/index/product.jpg"
                alt="CBD Facial Serum: Anti-Aging + Daily Moisturizer"
              />
          
          </div>
          <div className="product_main col-lg-6">
            <Link
              className="product_main-title"
              href="/"
              rel="noopener norefferer"
            >
              CBD Facial Serum: Anti-Aging + Daily Moisturizer
            </Link>
            <div className="product_main-rating d-flex align-items-center">
              <ul className="product_main-rating_stars d-flex align-items-center accent">
                <li className="star">
                  <i className="icon-star_fill" />
                </li>
                <li className="star">
                  <i className="icon-star_fill" />
                </li>
                <li className="star">
                  <i className="icon-star_fill" />
                </li>
                <li className="star">
                  <i className="icon-star_fill" />
                </li>
                <li className="star">
                  <i className="icon-star_fill" />
                </li>
              </ul>
              <a className="product_main-rating_amount" href="#s">
                (12)
              </a>
            </div>
            <p className="product_main-text">
              Quis risus sed vulputate odio ut. Arcu vitae elementum curabitur
              vitae nunc sed. Mauris a diam maecenas sed enim ut sem. Non enim
              praesent elementum facilisis. Sapien nec sagittis aliquam
              malesuada bibendum arcu vitae elementum
            </p>
            <ul className="product_main-list">
              <li className="list-item d-flex align-items-center">
                <i className="icon-check icon d-flex align-items-center justify-content-center" />
                Sativa-Dominant
              </li>
              <li className="list-item d-flex align-items-center">
                <i className="icon-check icon d-flex align-items-center justify-content-center" />{" "}
                THC 110.0-160.0mg/g
              </li>
              <li className="list-item d-flex align-items-center">
                <i className="icon-check icon d-flex align-items-center justify-content-center" />{" "}
                Euismod elementum
              </li>
              <li className="list-item d-flex align-items-center">
                <i className="icon-check icon d-flex align-items-center justify-content-center" />{" "}
                CBD 0.0-1.0mg/g
              </li>
            </ul>
            <div className="product_main-action d-flex align-items-center">
              <span className="price">$7.97</span>{" "}
              <Link className="btn" to="/shop">
                View
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;
