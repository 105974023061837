/* eslint-disable no-lone-blocks */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeItem, updateCartItemQuantity } from "../Redux/Addtochart";
import toast, { Toaster } from "react-hot-toast";
import { useState } from "react";
import Login from "../Login/Login";
import axios from "axios";
const Section1 = () => {
  const { items } = useSelector((state) => state.chart);
  const [showmodal, setshowmodal] = useState(false);
  console.log(items, "checking");
  const dispatch = useDispatch();
  const totalPrice = items
    ?.reduce((total, item) => total + item.qty * item.price, 0)
    .toFixed(2);
  const handleDecreaseClick = (data) => {
    if (data.qty === 1) {
      alert("Quantity Must me Greater Then Zero");
    } else {
      const newQuantity = data.qty - 1;
      dispatch(updateCartItemQuantity({ id: data.id, qty: newQuantity }));
    }
  };
  const Delete = (data) => {
    dispatch(removeItem(data));
    toast.success("Successfully Remove!");
  };
  const handleIncreaseClick = (data) => {
    const newQuantity = data.qty + 1;
    dispatch(updateCartItemQuantity({ id: data.id, qty: newQuantity }));
  };

  const Handlecheckout = async () => {
    const token = localStorage.getItem("accesstoken");

    if (!token) {
      setshowmodal(true);
    } else {
      if (items.length === 0) {
        toast.error("Please First Add Product");
      } else {
        const fromdata = new FormData();
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        items.forEach((data, index) => {
          fromdata.append(`cart[${index}][product]`, data.id);
          fromdata.append(`cart[${index}][quantity]`, data.qty);
        });
        try {
          const response = await axios.post(
            "https://api.weedowl.dev-iuh.xyz/api/user/orders",
            fromdata,
            {
              headers,
            }
          );
          toast.success(response.data.response);
          window.open(response.data.data);
        } catch (err) {
          console.error(err);
        }
      }
    }
  };

  return (
    <>
      <section className="cart section">
        <Toaster position="top-right" reverseOrder={false} />
        <div className="container d-md-flex justify-content-between align-content-start">
          <div className="cart_main">
            <h3 className="cart_main-header d-flex align-items-center justify-content-between">
              Products <span> Items {items.length}</span>
            </h3>
            <ul className="cart_main-list">
              {items.map((data, index) => {
                return (
                  <li className="cart_main-list_item">
                    <div className="wrapper d-flex flex-wrap flex-xl-nowrap align-items-center justify-content-between">
                      <div className="wrapper_item d-flex align-items-center">
                        <div
                          className="wrapper_item-media"
                          style={{ height: "100%" }}
                        >
                          <img
                            className="lazy"
                            src={data.image[0].media}
                            alt="media"
                          />
                        </div>
                        <div className="wrapper_item-info">
                          <h4 className="title">{data.name}</h4>
                          <span className="volume">{data.weight}mg</span>
                        </div>
                      </div>
                      <div className="price_wrapper d-flex flex-column">
                        {/* <span className="price price--old">${data.price}</span>{" "} */}
                        <span className="price price--new">
                          ${data.price.toFixed(2)}
                        </span>
                      </div>
                      <div className="price_wrapper price_wrapper--subtotal d-flex flex-column">
                        <h5 className="title">Subtotal</h5>
                        <span className="price price--total">
                          ${(data.price * data.qty).toFixed(2)}
                        </span>
                      </div>
                      <div className="qty d-flex align-items-center justify-content-between">
                        <span
                          className="qty_minus control disabled d-flex align-items-center"
                          onClick={() => handleDecreaseClick(data)}
                        >
                          <i className="icon-minus" />
                        </span>
                        <input
                          className="qty_amount"
                          type="number"
                          readOnly="readonly"
                          Value={data.qty}
                          min={1}
                          max={99}
                        />
                        <span
                          className="qty_plus control d-flex align-items-center"
                          onClick={() => handleIncreaseClick(data)}
                        >
                          <i className="icon-plus" />
                        </span>
                      </div>
                      <button
                        className="close d-flex align-items-center align-items-sm-start"
                        onClick={() => Delete(data.id)}
                      >
                        <i className="icon-close" />
                      </button>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="cart_summary">
            <h3 className="cart_summary-header">Order Summary</h3>

            <div className="cart_summary-table">
              <div className="cart_summary-table_row d-flex justify-content-between">
                <span className="property">Items </span>{" "}
                <span className="value">{items.length}</span>
              </div>
              <div className="cart_summary-table_row d-flex justify-content-between">
                <span className="property">Shipping</span>{" "}
                <span className="value">Free</span>
              </div>
              <div className="cart_summary-table_row cart_summary-table_row--total d-flex justify-content-between">
                <span className="property">Total</span>{" "}
                <span className="value">${totalPrice}</span>
              </div>
            </div>
            <button className="cart_summary-btn btn" onClick={Handlecheckout}>
              Checkout
            </button>
          </div>
        </div>
      </section>
      {showmodal && <Login setshowmodal={setshowmodal} />}
    </>
  );
};

export default Section1;
