import axios from "axios";
import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";

const Section1 = () => {
  const data = localStorage.getItem("user");
  const [showdata, ] = useState(JSON.parse(data));
  const [userregister, setuserregister] = useState({
    fname: showdata.fname,
    lname: showdata.lname,
    country: showdata.country,
    email: showdata.email,
    state: showdata.state,
    zipcode: showdata.zipcode,
    address: showdata.address,
    city: showdata.city,
  });
  const handleregisterget = (event) => {
    const { name, value } = event.target;
    setuserregister((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleRegistersubmit = async () => {
    const token = localStorage.getItem("accesstoken");
    try {
      const {
        fname,
        lname,
        email,
        zipcode,
        address,
        state,
        country,
        city,
      } = userregister;
      if (
        fname === "" ||
        lname === "" ||
        email === "" ||
        zipcode === "" ||
        address === "" ||
        state === "" ||
        country === "" ||
        city === ""
      ) {
        toast.error("please Fill All the feild");
      } else {
        const headers = {
            Authorization: `Bearer ${token}`,
          };
        const fromdata = new URLSearchParams();
        fromdata.append("fname", fname);
        fromdata.append("lname", lname);
        fromdata.append("email", email);
        fromdata.append("zipcode", zipcode);
        fromdata.append("address", address);
        fromdata.append("state", state);
        fromdata.append("country", country);
        fromdata.append("city", city);
        fromdata.append("website_id", "1");
        axios
          .put(
            `https://api.weedowl.dev-iuh.xyz/api/user/${showdata.id}`,
            fromdata,
            {
              headers,
            }
          )
          .then((res) => {
            console.log(res, "register");
            if (res.data.status === true) {
              toast.success(res.data.response);
              localStorage.setItem("user",JSON.stringify( res?.data?.data));
              
            }
          })
          .catch((err) => {
            toast.error(err.response.data.message);
          
          });
      }
    } catch (error) {
      console.log(error, "registererror");
    }
  };
  return (
    <div className="container">
      <Toaster position="top-right" reverseOrder={false} />

      <div>
        <div className="row login-from my-3 justify-content-center gap-3">
          <div className="row">
            <h3 className="mb-5 text-center">Update Profile</h3>
          </div>
          <div className="col-11 col-lg-5 col-xl-5 col-md-5">
            <label className="contacts_form-form_label" htmlFor="fname">
              First Name
            </label>
            <input
              type="text"
              defaultValue={showdata.fname}
              placeholder="First Name"
              className="contacts_form-form_field field required"
              onChange={handleregisterget}
              name="fname"
            />
          </div>
          <div className="col-11 col-lg-5 col-xl-5 col-md-5">
            <label className="contacts_form-form_label" htmlFor="lname">
              Last Name
            </label>
            <input
              type="text"
              defaultValue={showdata.lname}
              className="contacts_form-form_field field required"
              placeholder="Last Name"
              onChange={handleregisterget}
              name="lname"
            />
          </div>
          <div className="col-11 col-lg-5 col-xl-5 col-md-5">
            <label className="contacts_form-form_label" htmlFor="country">
              Country
            </label>
            <input
              type="text"
              defaultValue={showdata.country}
              className="contacts_form-form_field field required"
              placeholder="country"
              onChange={handleregisterget}
              name="country"
            />
          </div>
          <div className="col-11 col-lg-5 col-xl-5 col-md-5">
            <label className="contacts_form-form_label" htmlFor="state">
              State
            </label>
            <input
              type="text"
              defaultValue={showdata.state}
              className="contacts_form-form_field field required"
              placeholder="state"
              onChange={handleregisterget}
              name="state"
            />
          </div>
          <div className="col-11 col-lg-5 col-xl-5 col-md-5">
            <label className="contacts_form-form_label" htmlFor="city">
              City
            </label>
            <input
              type="text"
              defaultValue={showdata.city}
              className="contacts_form-form_field field required"
              placeholder="city"
              onChange={handleregisterget}
              name="city"
            />
          </div>
          <div className="col-11 col-lg-5 col-xl-5 col-md-5">
            <label className="contacts_form-form_label" htmlFor="zipcode">
              Zip-code
            </label>
            <input
              type="number"
              defaultValue={showdata.zipcode}
              className="contacts_form-form_field field required"
              placeholder="zipcode"
              onChange={handleregisterget}
              name="zipcode"
            />
          </div>
          <div className="col-11 col-lg-8 col-xl-10 col-md-10">
            <label className="contacts_form-form_label" htmlFor="address">
              Address
            </label>
            <input
              type="text"
              defaultValue={showdata.address}
              className="contacts_form-form_field field required"
              placeholder="address"
              onChange={handleregisterget}
              name="address"
            />
          </div>
          <div className="col-11 col-lg-8 col-xl-10 col-md-10">
            <label className="contacts_form-form_label" htmlFor="email">
              Email
            </label>
            <input
              type="text"
              defaultValue={showdata.email}
              className="contacts_form-form_field field required"
              placeholder="Email"
              onChange={handleregisterget}
              name="email"
            />
          </div>
          {/* <div className="col-11 col-lg-8 col-xl-10 col-md-10">
            <label className="contacts_form-form_label" htmlFor="password">
              password
            </label>
            <input
              type="text"
              value={showdata.password}
              className="contacts_form-form_field field required"
              placeholder="Password"
              onChange={handleregisterget}
              name="password"
            />
          </div> */}

          <button
            className="btn-custoom col-5 text-light mt-3"
            onClick={handleRegistersubmit}
          >
            Update Profile
          </button>
        </div>
      </div>
    </div>
  );
};

export default Section1;
