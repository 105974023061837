import React from 'react'
import PageHeader from '../Globalcomponent/PageHeader/PageHeader'
import Section1 from '../Contact/Section1'

const Contact = () => {
  return (
    <>
      <PageHeader title="Contact" description="Nibh tellus molestie nunc non blandit. Mi tempus imperdiet nulla malesuada pellentesque elit"/>
      <Section1/>
    </>
  )
}

export default Contact