import React from 'react'
import PageHeader from '../Globalcomponent/PageHeader/PageHeader'
import Section1 from '../About/Section1'
import Section2 from '../About/Section2'
import Section3 from '../About/Section3'
import Section4 from '../About/Section4'
import Section5 from '../About/Section5'
import Section6 from '../About/Section6'
// import Section7 from '../About/Section7'
import Section8 from '../About/Section8'
const About = () => {
    
  return (
    <>
   <PageHeader title="About" description="Nibh tellus molestie nunc non blandit. Mi tempus imperdiet nulla malesuada pellentesque elit"/>
    <Section1/>
    <Section2/>
    <Section3/>
    <Section4/>
    <Section5/>
    <Section6/>
    {/* <Section7/> */}
    <Section8/>
    </>
  )
}

export default About