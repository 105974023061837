import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css/autoplay';
import "swiper/css";
const Section8 = () => {
  return (
    <>
      <section className="instagram ">
        <div className="container">
          <div className="instagram_header">
            <h2 className="instagram_header-title">Follow Us on Instagram</h2>
            <p className="instagram_header-text">
              Accumsan sit amet nulla facilisi morbi tempus. Suscipit tellus
              mauris a diam maecenas sed enim ut sem
            </p>
          </div>
        </div>
        <Swiper
          slidesPerView={4}
          autoplay={{ delay: 3000 }}
        >
          <SwiperSlide>
            <div className="instagram_slider-slide swiper-slide">
              <a
                className="link"
                href="/"
                target="_blank"
                rel="noopener norefferer"
              >
                <img
                  className="lazy"
                  src="img/instagram/01.jpg"
                  alt="instagram post"
                />
                <span className="overlay d-flex justify-content-center align-items-center">
                  <i className="icon-instagram" />
                </span>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="instagram_slider-slide swiper-slide">
              <a
                className="link"
                href="/"
                target="_blank"
                rel="noopener norefferer"
              >
                <img
                  className="lazy"
                  src="img/instagram/02.jpg"
                  alt="instagram post"
                />
                <span className="overlay d-flex justify-content-center align-items-center">
                  <i className="icon-instagram" />
                </span>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="instagram_slider-slide swiper-slide">
              <a
                className="link"
                href="/"
                target="_blank"
                rel="noopener norefferer"
              >
                <img
                  className="lazy"
                  src="img/instagram/03.jpg"
                  alt="instagram post"
                />
                <span className="overlay d-flex justify-content-center align-items-center">
                  <i className="icon-instagram" />
                </span>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="instagram_slider-slide swiper-slide">
              <a
                className="link"
                href="/"
                target="_blank"
                rel="noopener norefferer"
              >
                <img
                  className="lazy"
                  src="img/instagram/04.jpg"
                  alt="instagram post"
                />
                <span className="overlay d-flex justify-content-center align-items-center">
                  <i className="icon-instagram" />
                </span>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="instagram_slider-slide swiper-slide">
              <a
                className="link"
                href="/"
                target="_blank"
                rel="noopener norefferer"
              >
                <img
                  className="lazy"
                  src="img/instagram/05.jpg"
                  alt="instagram post"
                />
                <span className="overlay d-flex justify-content-center align-items-center">
                  <i className="icon-instagram" />
                </span>
              </a>
            </div>
          </SwiperSlide>
        </Swiper>
        {/* <div className="instagram_slider swiper">
          <div className="swiper-wrapper"></div>
        </div> */}
      </section>
    </>
  );
};

export default Section8;
