import React from "react";

const Section2 = () => {
  return (
    <>
      <div className="promo section--nopb  mb-5">
        <div className="container d-flex flex-column flex-md-row">
          <div className="promo_banner promo_banner--big" >
            <h3 className="promo_banner-header">
              Browse our selection of CBD-infused goods
            </h3>
            <p className="promo_banner-text" >
              Elementum eu facilisis sed odio morbi quis commodo odio. Mauris
              rhoncus aenean vel elit scelerisque mauris pellentesque
            </p>
            <a className="promo_banner-btn btn" href="/">
              Shop Now
            </a>
          </div>
          <div
            className="promo_banner promo_banner--small d-flex flex-column justify-content-end align-items-start"
          >
            <h3 className="promo_banner-header">Weekly Special</h3>
            <a className="promo_banner-btn btn" href="/">
              Shop Now
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Section2;
