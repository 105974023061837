import React from 'react'
import PageHeader from '../Globalcomponent/PageHeader/PageHeader'
import Section1 from '../Profile/Section1'

const Profile = () => {
  return (
    <>
     <PageHeader title="Profile Page" description="Nibh tellus molestie nunc non blandit. Mi tempus imperdiet nulla malesuada pellentesque elit"/>
     <Section1/>
    </> 
  )
}

export default Profile