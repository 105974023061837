import axios from "axios";
import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";

const Section1 = () => {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [message, setmessage] = useState("");
  const handlesubmit = (event) => {
    if (email === "" || name === "" || message === "") {
      toast.error("please Fill All Field");
    } else {
      const fromdata = new FormData();
      fromdata.append("name", name);
      fromdata.append("email", email);
      fromdata.append("message", message);
      axios
        .post("https://api.weedowl.dev-iuh.xyz/api/contact", fromdata)
        .then((res) => {
          if (res.data.status === true) toast.success(res.data.response);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <>
      <div className="contacts  my-3">
        <Toaster position="top-right" reverseOrder={false} />
        <div className="container d-flex flex-wrap flex-xl-nowrap justify-content-between">
          <div className="contacts_form col-12 col-lg-5">
            <div className="contacts_form-header">
              <h2 className="contacts_form-header_title">
                Have any Questions?
              </h2>
              <p className="contacts_form-header_text">
                Elementum eu facilisis sed odio morbi quis commodo odio. Mauris
                rhoncus aenean vel elit scelerisque mauris pellentesque
              </p>
            </div>
            <div className="contacts_form-form d-flex flex-column">
              <label
                className="contacts_form-form_label"
                htmlFor="contactsName"
              >
                Your Name
              </label>
              <input
                className="contacts_form-form_field field required"
                type="text"
                name="contactsName"
                id="contactsName"
                onChange={(e) => setname(e.target.value)}
                placeholder="Your name"
              />{" "}
              <label
                className="contacts_form-form_label"
                htmlFor="contactsEmail"
              >
                Your Email
              </label>{" "}
              <input
                className="contacts_form-form_field field required"
                type="text"
                data-type="email"
                name="contactsEmail"
                id="contactsEmail"
                onChange={(e) => setemail(e.target.value)}
                placeholder="you@example.com"
              />{" "}
              <label
                className="contacts_form-form_label"
                htmlFor="contactsMessage"
              >
                Message
              </label>{" "}
              <textarea
                className="contacts_form-form_field field required"
                data-type="message"
                name="contactsMessage"
                id="contactsMessage"
                onChange={(e) => setmessage(e.target.value)}
                placeholder="Type Your Message"
                defaultValue={""}
              />{" "}
              <button
                className="contacts_form-form_btn btn"
                onClick={handlesubmit}
                type="submit"
              >
                Send a Message
              </button>
            </div>
          </div>
          <div className="contacts_info col-12 col-lg-6 col-xl-auto flex-xl-grow-1">
            {/* <div className="contacts_info-map">
              <div id="map" />
            </div> */}
            <ul className="contacts_info-list">
              <li className="contacts_info-list_item d-flex flex-column flex-sm-row align-items-center">
                <span className="icon d-flex justify-content-center align-items-center">
                  <i className="icon-location" />
                </span>
                <div className="main d-flex flex-column">
                  <span>211 Lehner Valleys Apt. 287</span>{" "}
                  <span>Harrisstad</span>
                </div>
              </li>
              <li className="contacts_info-list_item d-flex flex-column flex-sm-row align-items-center">
                <span className="icon d-flex justify-content-center align-items-center">
                  <i className="icon-call" />
                </span>
                <div className="main d-flex flex-column">
                  <a className="link" href="tel:+1234567890">
                    +1-896-882-3255
                  </a>{" "}
                  <a className="link" href="tel:+1234567890">
                    +1-896-882-3255
                  </a>
                </div>
              </li>
              <li className="contacts_info-list_item d-flex flex-column flex-sm-row align-items-center">
                <span className="icon d-flex justify-content-center align-items-center">
                  <i className="icon-envelope_open" />
                </span>
                <div className="main d-flex flex-column">
                  <a className="link" href="mailto:example@domain.com">
                    abc@gmail.com
                  </a>{" "}
                  <a className="link" href="mailto:example@domain.com">
                    abc@gmail.com
                  </a>
                </div>
              </li>
            </ul>
            <ul className="contacts_info-socials d-flex align-items-center justify-content-center justify-content-sm-start">
              <li className="list-item">
                <a
                  className="link"
                  href="/"
                  target="_blank"
                  rel="noopener norefferer"
                >
                  <i className="icon-facebook icon" />
                </a>
              </li>
              <li className="list-item">
                <a
                  className="link"
                  href="/"
                  target="_blank"
                  rel="noopener norefferer"
                >
                  <i className="icon-instagram icon" />
                </a>
              </li>
              <li className="list-item">
                <a
                  className="link"
                  href="/"
                  target="_blank"
                  rel="noopener norefferer"
                >
                  <i className="icon-twitter icon" />
                </a>
              </li>
              <li className="list-item">
                <a
                  className="link"
                  href="/"
                  target="_blank"
                  rel="noopener norefferer"
                >
                  <i className="icon-whatsapp icon" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Section1;
