import React from 'react'
import PageHeader from '../Globalcomponent/PageHeader/PageHeader'
import Section6 from '../About/Section6'
const Team = () => {
  return (
    <>
    <PageHeader title="Our Team" description="Nibh tellus molestie nunc non blandit. Mi tempus imperdiet nulla malesuada pellentesque elit"/>
    <Section6/>
    </>
  )
}

export default Team