import React from 'react'
import Section1 from '../Cart/Section1'
import PageHeader from '../Globalcomponent/PageHeader/PageHeader'

const Checkout = () => {
  return (
    <>
    <PageHeader title="Checkout Page" description="Nibh tellus molestie nunc non blandit. Mi tempus imperdiet nulla malesuada pellentesque elit"/>
    <Section1/>
    </>
  )
}

export default Checkout