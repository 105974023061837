import React from 'react'
import PageHeader from '../Globalcomponent/PageHeader/PageHeader'
import Section1 from '../Wishlist/section1'
import Section2 from '../Wishlist/Section2'
import Section8 from '../About/Section8'
const Wishlist = () => {
  return (
    <>
        <PageHeader title="Wishlist" description="Nibh tellus molestie nunc non blandit. Mi tempus imperdiet nulla malesuada pellentesque elit"/>
          <Section1/>
          <Section2/>
          <Section8/>
    </>
  )
}

export default Wishlist