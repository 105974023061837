import React from 'react'
import PageHeader from '../Globalcomponent/PageHeader/PageHeader'
import Section1 from '../Shop/Section1'
import Section2 from '../Shop/Section2'
// import Section3 from '../Shop/Section3'
import Section8 from '../About/Section8'

const Shop = () => {
  return (
 <>
    <PageHeader title='shop' description="Nibh tellus molestie nunc non blandit. Mi tempus imperdiet nulla malesuada pellentesque elit"/>
    <Section1/>
    <Section2/>
    {/* <Section3/> */}
    <Section8/>
 </>
  )
}

export default Shop