import React from "react";

const Section5 = () => {
  return (
    <>
      <section className="guide section">
        <div className="container d-flex flex-column-reverse flex-lg-row">
          <div className="guide_media">
          
            <img
                className="lazy guide_media-img"
                src="img/about/guide.jpg"
                alt="media"
              />
            <span className="underlay">
              <span className="underlay_circle underlay_circle--accent" />{" "}
              <span className="underlay_circle underlay_circle--green" />
            </span>
          </div>
          <div className="guide_main col-lg-6 offset-xxl-6">
            <div className="guide_main-header">
              <h2 className="guide_main-header_title">
                How to Buy Products Online
              </h2>
              <p className="guide_main-header_text">
                Elementum eu facilisis sed odio morbi quis commodo odio. Mauris
                rhoncus aenean vel elit scelerisque mauris pellentesque
              </p>
            </div>
            <ul className="guide_main-list">
              <li
                className="guide_main-list_item d-flex justify-content-between"
                // data-aos="fade-up"
              >
                <span className="step d-flex align-items-center justify-content-center">
                  1
                </span>
                <div className="wrapper">
                  <h5 className="title">Sing up &amp; Get verified</h5>
                  <p className="text">
                    Metus vulputate eu scelerisque felis imperdiet proin
                    fermentum leo
                  </p>
                </div>
              </li>
              <li
                className="guide_main-list_item d-flex justify-content-between"
                // data-aos="fade-up"
              >
                <span className="step d-flex align-items-center justify-content-center">
                  2
                </span>
                <div className="wrapper">
                  <h5 className="title">Order online</h5>
                  <p className="text">
                    Eget dolor morbi non arcu risus quis varius quam quisque
                  </p>
                </div>
              </li>
              <li
                className="guide_main-list_item d-flex justify-content-between"
                // data-aos="fade-up"
              >
                <span className="step d-flex align-items-center justify-content-center">
                  3
                </span>
                <div className="wrapper">
                  <h5 className="title">Receive your order</h5>
                  <p className="text">
                    Fringilla phasellus faucibus scelerisque eleifend donec
                    pretium vulputate
                  </p>
                </div>
              </li>
            </ul>
            <div className="guide_main-secondary d-flex flex-column flex-sm-row justify-content-between">
              <div className="guide_main-secondary_item">
                <h5 className="title">Express delivery service</h5>
                <p className="text">
                  Arcu felis bibendum ut tristique et egestas. Id semper risus
                  in hendrerit gravida rutrum
                </p>
              </div>
              <div className="guide_main-secondary_item">
                <h5 className="title">Support team is available 24/7</h5>
                <div className="wrapper d-flex align-items-center">
                  <span className="icon d-flex align-items-center justify-content-center">
                    <i className="icon-call" />
                  </span>
                  <div className="wrapper d-flex flex-column">
                    <span>Phone number</span>{" "}
                    <a className="link" href="tel:+1234567890">
                      +1-202-555-0133
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Section5;
