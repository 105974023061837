import React, { useState, useRef, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";

import Logo from "../../images/logo.png";
import onlyOwl from "../../images/onlyOwl.png";
import onlyText from "../../images/onlyImage.png";
import animation from "../../images/animation-owl.gif";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { removeItem } from "../../Redux/Addtochart";
import toast, { Toaster } from "react-hot-toast";
import Login from "../../Login/Login";
const Header = () => {
  const { items } = useSelector((state) => state.chart);
  const [showprofile, hideprofile] = useState(false);
  const [token, settoken] = useState('');
  const [showmodal, setshowmodal] = useState(false);
  const Navigate = useNavigate()
  useEffect(() => {
    const token = localStorage.getItem("accesstoken");
    settoken(token)
  }, [token,showmodal]);
  const handleprofile = () => {
    hideprofile(!showprofile);
  };
  const handlelogin = () => {
    setshowmodal(true);
    hideprofile(false)
  };
  const handlelogout = () => {
    localStorage.removeItem("accesstoken");
    settoken('')
    hideprofile(false)
    Navigate('/')
  };
  const totalPrice = items
    .reduce((total, item) => total + item.qty * item.price, 0)
    .toFixed(2);
  const disptach = useDispatch();
  console.log(items, "cartitems");
  const Delete = (data) => {
    disptach(removeItem(data));
    toast.success("Successfully Remove!");
  };
  const [perviousIndex, setPerviousIndex] = useState(null);
  const [isFlying, setIsFlying] = useState(false);
  const birdRef = useRef(null);
  const controls = useAnimation();
  const [activeItem, setActiveItem] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [lgscreencheck, setlgscreen] = useState(window.innerWidth);
  const [show, hide] = useState(false);
  const handleResize = () => {
    setlgscreen(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [lgscreencheck]);

  const flyTo = (x, y, flipGif) => {
    controls.start({
      display: "block",
      x: x,
      y: y,
      top: 0,
      transformOrigin: "center",
      scaleX: flipGif ? "-1" : "1",
      transition: { duration: 2 },
      onComplete: { display: "none" },
    });
  };

  const handleItemClick = (event, item, index) => {
    if (lgscreencheck >= 1028) {
      const { clientX, clientY } = event;
      setIsActive(false);
      setIsFlying(true);
      setPerviousIndex(index);
      setActiveItem(item); // Set the active item
      let flipGif;
      if (perviousIndex === null) {
        flipGif = false;
      } else {
        flipGif = perviousIndex > index; // Adjust as needed
      }
      if (item === "logo") {
        setIsActive(false);
        setPerviousIndex(null);
      }
      const screenWidth =
        window.innerWidth || document.documentElement.clientWidth;
      const percentageAdjustment = (80 / screenWidth) * 100;
      const difference = clientX - percentageAdjustment;

      flyTo(difference - 100, clientY - 100, flipGif);
    }
  };

  const navItem = [
    { name: "Home", link: "/" },
    { name: "Shop", link: "shop" },
    { name: "About", link: "about" },
    { name: "Contact Us", link: "contact" },
  ];
  useEffect(() => {
    if (birdRef.current) {
      birdRef.current.style.display = "none";
    }
  }, []);

  const toggle = () => {
    hide(!show);
  };

  return (
    <>
      <header
        className="header d-flex flex-wrap align-items-center"
        data-page="home"
        data-overlay="true"
      >
        <Toaster position="top-right" reverseOrder={false} />
        <div className="container-fluid  d-flex flex-wrap flex-xl-nowrap align-items-center justify-content-end">
          <Link
            className="brand header_logo d-flex align-items-center"
            href="/"
            onClick={(event) => handleItemClick(event, "logo", -1)}
          >
            <motion.img
              ref={birdRef}
              src={animation}
              alt="Flying Bird"
              initial={false}
              animate={controls}
              onAnimationComplete={() => {
                setTimeout(() => {
                  birdRef.current.style.display = "none";
                }, 20);
                if (perviousIndex === null) {
                  setIsFlying(false);
                }
                setIsActive(true);
              }}
              style={{
                position: "absolute",
                width: "200px", // Adjust the size as needed
                height: "auto",
                display: isActive ? "none" : "block",
              }}
            />
            {lgscreencheck >= 1028 ? (
              <img
                src={!isFlying ? Logo : onlyText}
                alt="logo"
                className="logo logo2"
                style={{
                  objectFit: `${!isFlying ? "" : "contain"}`,
                  width: "130px",
                }}
              />
            ) : (
              <img
                src={onlyText}
                alt="logo"
                className="logo logo2"
                style={{
                  objectFit: `${!isFlying ? "" : "contain"}`,
                  width: "130px",
                }}
              />
            )}
          </Link>
          <nav className={show ? "header_nav active" : "header_nav"}>
            <ul className="header_nav-list">
              {navItem.map((e, i) => {
                return (
                  <li className="header_nav-list_item active">
                    {activeItem === e.name && isActive && (
                      <img src={onlyOwl} alt="weedowl" className="active-owl" />
                    )}
                    <Link
                      className="nav-link dropdown-toggle d-inline-flex align-items-center"
                      to={e.link}
                      onClick={(event) => handleItemClick(event, e.name, i)}
                    >
                      {e.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </nav>
          <span
            className="header_trigger d-inline-flex d-xl-none flex-column justify-content-between"
            onClick={toggle}
          >
            <span className="line line--short" /> <span className="line line" />
            <span className="line line--short" /> <span className="line line" />
          </span>
          <div className="header_user d-flex  justify-content-end align-items-center">
            <Link
              className="header_user-action d-inline-flex align-items-center justify-content-center"
              to="/wishlist"
            >
              <i className="icon-heart" />
            </Link>

            <a
              className="header_user-action d-inline-flex align-items-center justify-content-center"
              data-bs-toggle="offcanvas"
              data-bs-target="#cartOffcanvas"
              aria-controls="cartOffcanvas"
              href="s"
              style={{ position: "relative" }}
            >
              <i className="icon-basket" />
            </a>
            <button
              className="header_user-action d-inline-flex align-items-center justify-content-center"
              onClick={handleprofile}
            >
              <i className="fa fa-user" />
            </button>
          </div>
          <div className={showprofile ? "card" : "hidden"}>
            <ul >
              <li className={token ? "" : "hidden"}>
              <i class="fa fa-user m-2" aria-hidden="true"></i>
                <Link to={'/profile'} >Profile </Link>
              </li>

              <li>
              <i class="fa fa-sign-in m-2" aria-hidden="true"></i>
                <button onClick={token ? handlelogout : handlelogin}>
                  {token ? "Logout" : "Sign In"}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </header>
      <div
        className="cartOffcanvas offcanvas offcanvas-end"
        tabIndex={-1}
        id="cartOffcanvas"
      >
        <div className="cartOffcanvas_header d-flex align-items-center justify-content-between">
          <h2 className="cartOffcanvas_header-title" id="cartOffcanvas">
            Cart
          </h2>
          <button
            className="cartOffcanvas_header-close"
            type="button"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <i className="icon-close" />
          </button>
        </div>
        <div className="cartOffcanvas_body">
          <ul className="cartOffcanvas_body-list">
            {items?.map((data, index) => {
              return (
                <li
                  className="cartOffcanvas_body-list_item d-sm-flex align-items-center"
                  key={index}
                >
                  <div className="media">
                    <img
                      className="lazy"
                      src={data?.image[0]?.media}
                      alt="media"
                    />
                  </div>
                  <div className="main d-flex flex-wrap justify-content-between align-items-end align-items-lg-center">
                    <h4 className="main_title-product">{data.name}</h4>
                    <div className="main_price">
                      <span className="price">${data.price}</span>
                    </div>

                    <button
                      className="btn--underline"
                      onClick={() => Delete(data.id)}
                    >
                      Remove
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="cartOffcanvas_body-total d-flex justify-content-between align-items-center">
            <span>Total</span>
            <span className="cartTotal">${totalPrice}</span>
          </div>
          <Link className="cartOffcanvas_body-btn btn" to="/checkout">
            Proceed to checkout
          </Link>
        </div>
      </div>
      {showmodal && <Login setshowmodal={setshowmodal} />}
    </>
  );
};

export default Header;
