// wishlistSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
};

const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    addItemInList: (state, action) => {
      const newItem = action.payload;
      const existingItem = state.items.find((item) => item.id === newItem.id);

      if (!existingItem) {
        state.items.push(newItem);
      }
    },
    clearWishlist: (state) => {
      state.items = [];
    },
    removeItemfromlist: (state, action) => {
      const itemId = action.payload;
      state.items = state.items.filter((item) => item.id !== itemId);
    },
  },
});

export const { addItemInList, removeItemfromlist,clearWishlist } = wishlistSlice.actions;
export default wishlistSlice.reducer;
