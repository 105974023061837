import { BrowserRouter, Routes,Route } from "react-router-dom";
import Home from "./Pages/Home";
import Header from "./Globalcomponent/header/Header";
import Footer from "./Globalcomponent/Footer/Footer";
import About from "./Pages/About";
import Team from "./Pages/Team";
import Faqs from "./Pages/Faqs";
import Wishlist from "./Pages/Wishlist";
import Contact from "./Pages/Contact";
import Shop from './Pages/Shop'
import Singleproduct from "./Pages/Singleproduct";
import Checkout from "./Pages/Checkout";
import Weeklyoffer from "./Pages/Weeklyoffer";
import Newsdetail from "./Home/Latestnews/Newsdetail";
import Profilepage from "./Pages/Profile";
function App() {


  
  return (
    <div className="App">
       <BrowserRouter>
       <Header/>
         <Routes>
           <Route path="/" element={<Home/>}/>
           <Route path="/about" element={<About/>}/>
           <Route path="/team" element={<Team/>}/>
           <Route path="/faqs" element={<Faqs/>}/>
           <Route path="/wishlist" element={<Wishlist/>}/>
           <Route path="/contact" element={<Contact/>}/>
           <Route path="/shop" element={<Shop/>}/>
           <Route path="/profile" element={<Profilepage/>}/>
           <Route path="/weeklyoffer" element={<Weeklyoffer/>}/>
           <Route path="/checkout" element={<Checkout/>}/>
           <Route path="/singleproduct/:id" element={<Singleproduct/>}/>
           <Route path="/new/:id" element={<Newsdetail/>}/>
         </Routes>
         <Footer/>
       </BrowserRouter>
    </div>
  );
}

export default App;
