import React from 'react'
import PageHeader from '../Globalcomponent/PageHeader/PageHeader'
import Section1 from '../Faqs/Section1'

const Faqs = () => {
  return (
    <>
     <PageHeader title="Popular Questions" description="Nibh tellus molestie nunc non blandit. Mi tempus imperdiet nulla malesuada pellentesque elit"/>
     <Section1/>
    </>
  )
}

export default Faqs