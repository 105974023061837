import React from 'react'
import PageHeader from '../Globalcomponent/PageHeader/PageHeader'
import Section1 from '../SingleProduct/Section1'
// import SectionReviw from '../SingleProduct/SectionReviw'
// import Section2 from '../SingleProduct/Section2'

const Singleproduct = () => {
  return (
    <>
    <PageHeader title='Single Product' description="Nibh tellus molestie nunc non blandit. Mi tempus imperdiet nulla malesuada pellentesque elit"/>
    <Section1/>
    {/* <SectionReviw/> */}

    {/* <Section2/> */}
    </>
  )
}

export default Singleproduct