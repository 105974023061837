import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeItemfromlist } from "../Redux/wishlistSlice";
import toast, { Toaster } from "react-hot-toast";
import { addItem } from "../Redux/Addtochart";
import StarRatings from "react-star-ratings";
const Section1 = () => {
  const Handleremove = (id) => {
    disptach(removeItemfromlist(id));
    toast.success("Successfully Remove To List !");
  };
  const Addtochart = (data) => {
    const Datatosend = {
      image: data?.image,
      name: data?.name,
      price: data?.price,
      qty: 1,
      id: data?.id,
      rating :data?.rating,
      thc:data?.thc,
      cbd:data?.cbd,
      weight:data?.weight
    };
    console.log(data,'adsadas')
    disptach(addItem(Datatosend));
    toast.success("Added Successfully!");
  };
  const disptach = useDispatch();
  const { items } = useSelector((state) => state.wishlist);
  console.log(items,'wishtlisht')
  return (
    <>
      <section className="products section--nopb">
        <Toaster position="top-right" reverseOrder={false} />
        <div className="container">
          <div className="products_list d-grid">
            {items.map((data, index) => {
              return (
                <div className="products_list-item">
                  <div className="products_list-item_wrapper d-flex flex-column">
                    <div className="media">
                      <img src={data?.image[0].media} alt="sda" />

                      <div className="overlay d-flex justify-content-between align-items-start">
                        <div className="action d-flex flex-column">
                          <div
                            className="action_link active d-flex align-items-center justify-content-center"
                            data-role="wishlist"
                            onClick={() => Handleremove(data.id)}
                          >
                            <i className="icon-heart" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="main d-flex flex-column align-items-center justify-content-between">
                      <div className="main_rating">
                        <StarRatings
                          rating={data.rating}
                          starRatedColor="#efc368"
                          numberOfStars={5}
                          starDimension="30px"
                          name="rating"
                        />
                      </div>
                      <a
                        className="main_title"
                        href="product.html"
                        target="_blank"
                        rel="noopener norefferer"
                      >
                        {data.name}
                      </a>
                      <ul className="main_table d-flex flex-column align-items-center">
                        <li className="list-item">
                          <span className="property">THC</span>{" "}
                          <span className="value">{data.thc}%</span>
                        </li>
                        <li className="list-item">
                          <span className="property">CBD</span>{" "}
                          <span className="value">{data.cbd}%</span>
                        </li>
                      </ul>
                      <div className="main_price">
                        <span className="price">${data.price}</span>
                      </div>
                      <button className="btn btn--green" onClick={() => Addtochart(data)} >
                        Add to Cart
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Section1;
