import React from 'react'
import { Link } from 'react-router-dom'

const PageHeader = ({title,description}) => {
  return (
    <header className="page">
    <div className="page_main container-fluid">
      <div className="container mt-5">
        <h1 className="page_header">{title}</h1>
        <p className="page_text ">
         {
            description
         }
        </p>
      </div>
    </div>
    <div className="container">
      <ul className="page_breadcrumbs d-flex flex-wrap">
        <li className="page_breadcrumbs-item">
          <Link className="link" to="/">
            Home
          </Link>
        </li>
        <li className="page_breadcrumbs-item current">
          <span>{title}</span>
        </li>
      </ul>
    </div>
  </header>
  )
}

export default PageHeader