import React from "react";

const Section6 = () => {
  return (
    <>
      <section className="team section--nopb mb-5">
        <div className="container">
          <div className="team_header">
            <h2 className="team_header-title">Meet the Specialist Team</h2>
            <p className="team_header-text">
              Vivamus at augue eget arcu dictum varius. Pharetra et ultrices
              neque ornare
            </p>
          </div>
          <ul className="team_list d-flex flex-wrap">
            <li
              className="team_list-item col-12 col-md-6 col-xl-4 col-xxl-3"
              data-order={1}
            //   data-aos="fade-up"
            >
              <div className="wrapper d-flex flex-column">
                <div className="team_list-item_img">
                 
                  <img
                      className="lazy img"
                      src="img/team/01.jpg"
                      alt="Richard Edwards"
                    />
                  <div className="overlay d-flex justify-content-center align-items-end">
                    <ul className="overlay_socials d-flex justify-content-center align-items-center">
                      <li className="list-item">
                        <a
                          className="link d-inline-flex justify-content-center align-items-center"
                          href="/"
                          target="_blank"
                          rel="noopener norefferer"
                        >
                          <i className="icon-facebook" />
                        </a>
                      </li>
                      <li className="list-item">
                        <a
                          className="link d-inline-flex justify-content-center align-items-center"
                          href="/"
                          target="_blank"
                          rel="noopener norefferer"
                        >
                          <i className="icon-instagram" />
                        </a>
                      </li>
                      <li className="list-item">
                        <a
                          className="link d-inline-flex justify-content-center align-items-center"
                          href="/"
                          target="_blank"
                          rel="noopener norefferer"
                        >
                          <i className="icon-twitter" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team_list-item_info d-flex flex-column align-items-center">
                  <span className="name">Richard Edwards</span>{" "}
                  <span className="profession secondary">
                    CEO &amp; Founder
                  </span>
                </div>
              </div>
            </li>
            <li
              className="team_list-item col-12 col-md-6 col-xl-4 col-xxl-3"
              data-order={2}
            //   data-aos="fade-up"
            >
              <div className="wrapper d-flex flex-column">
                <div className="team_list-item_img">
                 
                  <img
                      className="lazy img"
                      src="img/team/02.jpg"
                      alt="Maria White"
                    />
                  <div className="overlay d-flex justify-content-center align-items-end">
                    <ul className="overlay_socials d-flex justify-content-center align-items-center">
                      <li className="list-item">
                        <a
                          className="link d-inline-flex justify-content-center align-items-center"
                          href="/"
                          target="_blank"
                          rel="noopener norefferer"
                        >
                          <i className="icon-facebook" />
                        </a>
                      </li>
                      <li className="list-item">
                        <a
                          className="link d-inline-flex justify-content-center align-items-center"
                          href="/"
                          target="_blank"
                          rel="noopener norefferer"
                        >
                          <i className="icon-instagram" />
                        </a>
                      </li>
                      <li className="list-item">
                        <a
                          className="link d-inline-flex justify-content-center align-items-center"
                          href="/"
                          target="_blank"
                          rel="noopener norefferer"
                        >
                          <i className="icon-twitter" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team_list-item_info d-flex flex-column align-items-center">
                  <span className="name">Maria White</span>{" "}
                  <span className="profession secondary">Support Engineer</span>
                </div>
              </div>
            </li>
            <li
              className="team_list-item col-12 col-md-6 col-xl-4 col-xxl-3"
              data-order={3}
            //   data-aos="fade-up"
            >
              <div className="wrapper d-flex flex-column">
                <div className="team_list-item_img">
                 
                  <img
                      className="lazy img"
                      src="img/team/03.jpg"
                      alt="Chris Gibson"
                    />
                  <div className="overlay d-flex justify-content-center align-items-end">
                    <ul className="overlay_socials d-flex justify-content-center align-items-center">
                      <li className="list-item">
                        <a
                          className="link d-inline-flex justify-content-center align-items-center"
                          href="/"
                          target="_blank"
                          rel="noopener norefferer"
                        >
                          <i className="icon-facebook" />
                        </a>
                      </li>
                      <li className="list-item">
                        <a
                          className="link d-inline-flex justify-content-center align-items-center"
                          href="/"
                          target="_blank"
                          rel="noopener norefferer"
                        >
                          <i className="icon-instagram" />
                        </a>
                      </li>
                      <li className="list-item">
                        <a
                          className="link d-inline-flex justify-content-center align-items-center"
                          href="/"
                          target="_blank"
                          rel="noopener norefferer"
                        >
                          <i className="icon-twitter" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team_list-item_info d-flex flex-column align-items-center">
                  <span className="name">Chris Gibson</span>{" "}
                  <span className="profession secondary">Lab Assistant</span>
                </div>
              </div>
            </li>
            <li
              className="team_list-item col-12 col-md-6 col-xl-4 col-xxl-3"
              data-order={4}
            //   data-aos="fade-up"
            >
              <div className="wrapper d-flex flex-column">
                <div className="team_list-item_img">
                 
                  <img
                      className="lazy img"
                      src="img/team/04.jpg"
                      alt="Linda Nelson"
                    />
                  <div className="overlay d-flex justify-content-center align-items-end">
                    <ul className="overlay_socials d-flex justify-content-center align-items-center">
                      <li className="list-item">
                        <a
                          className="link d-inline-flex justify-content-center align-items-center"
                          href="/"
                          target="_blank"
                          rel="noopener norefferer"
                        >
                          <i className="icon-facebook" />
                        </a>
                      </li>
                      <li className="list-item">
                        <a
                          className="link d-inline-flex justify-content-center align-items-center"
                          href="/"
                          target="_blank"
                          rel="noopener norefferer"
                        >
                          <i className="icon-instagram" />
                        </a>
                      </li>
                      <li className="list-item">
                        <a
                          className="link d-inline-flex justify-content-center align-items-center"
                          href="/"
                          target="_blank"
                          rel="noopener norefferer"
                        >
                          <i className="icon-twitter" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team_list-item_info d-flex flex-column align-items-center">
                  <span className="name">Linda Nelson</span>{" "}
                  <span className="profession secondary">Lab Assistant</span>
                </div>
              </div>
            </li>
            <li
              className="team_list-item col-12 col-md-6 col-xl-4 col-xxl-3"
              data-order={5}
            //   data-aos="fade-up"
            >
              <div className="wrapper d-flex flex-column">
                <div className="team_list-item_img">
                 
                  <img
                      className="lazy img"
                      src="img/team/05.jpg"
                      alt="Katrina King"
                    />
                  <div className="overlay d-flex justify-content-center align-items-end">
                    <ul className="overlay_socials d-flex justify-content-center align-items-center">
                      <li className="list-item">
                        <a
                          className="link d-inline-flex justify-content-center align-items-center"
                          href="/"
                          target="_blank"
                          rel="noopener norefferer"
                        >
                          <i className="icon-facebook" />
                        </a>
                      </li>
                      <li className="list-item">
                        <a
                          className="link d-inline-flex justify-content-center align-items-center"
                          href="/"
                          target="_blank"
                          rel="noopener norefferer"
                        >
                          <i className="icon-instagram" />
                        </a>
                      </li>
                      <li className="list-item">
                        <a
                          className="link d-inline-flex justify-content-center align-items-center"
                          href="/"
                          target="_blank"
                          rel="noopener norefferer"
                        >
                          <i className="icon-twitter" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team_list-item_info d-flex flex-column align-items-center">
                  <span className="name">Katrina King</span>{" "}
                  <span className="profession secondary">Sales Manager</span>
                </div>
              </div>
            </li>
            <li
              className="team_list-item col-12 col-md-6 col-xl-4 col-xxl-3"
              data-order={6}
            //   data-aos="fade-up"
            >
              <div className="wrapper d-flex flex-column">
                <div className="team_list-item_img">
                 
                  <img
                      className="lazy img"
                      src="img/team/06.jpg"
                      alt="John Anderson"
                    />
                  <div className="overlay d-flex justify-content-center align-items-end">
                    <ul className="overlay_socials d-flex justify-content-center align-items-center">
                      <li className="list-item">
                        <a
                          className="link d-inline-flex justify-content-center align-items-center"
                          href="/"
                          target="_blank"
                          rel="noopener norefferer"
                        >
                          <i className="icon-facebook" />
                        </a>
                      </li>
                      <li className="list-item">
                        <a
                          className="link d-inline-flex justify-content-center align-items-center"
                          href="/"
                          target="_blank"
                          rel="noopener norefferer"
                        >
                          <i className="icon-instagram" />
                        </a>
                      </li>
                      <li className="list-item">
                        <a
                          className="link d-inline-flex justify-content-center align-items-center"
                          href="/"
                          target="_blank"
                          rel="noopener norefferer"
                        >
                          <i className="icon-twitter" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team_list-item_info d-flex flex-column align-items-center">
                  <span className="name">John Anderson</span>{" "}
                  <span className="profession secondary">Sales Manager</span>
                </div>
              </div>
            </li>
            <li
              className="team_list-item col-12 col-md-6 col-xl-4 col-xxl-3"
              data-order={7}
            //   data-aos="fade-up"
            >
              <div className="wrapper d-flex flex-column">
                <div className="team_list-item_img">
                
                  <img
                      className="lazy img"
                      src="img/team/07.jpg"
                      alt="Randy Stewart"
                    />
                  <div className="overlay d-flex justify-content-center align-items-end">
                    <ul className="overlay_socials d-flex justify-content-center align-items-center">
                      <li className="list-item">
                        <a
                          className="link d-inline-flex justify-content-center align-items-center"
                          href="/"
                          target="_blank"
                          rel="noopener norefferer"
                        >
                          <i className="icon-facebook" />
                        </a>
                      </li>
                      <li className="list-item">
                        <a
                          className="link d-inline-flex justify-content-center align-items-center"
                          href="/"
                          target="_blank"
                          rel="noopener norefferer"
                        >
                          <i className="icon-instagram" />
                        </a>
                      </li>
                      <li className="list-item">
                        <a
                          className="link d-inline-flex justify-content-center align-items-center"
                          href="/"
                          target="_blank"
                          rel="noopener norefferer"
                        >
                          <i className="icon-twitter" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team_list-item_info d-flex flex-column align-items-center">
                  <span className="name">Randy Stewart</span>{" "}
                  <span className="profession secondary">Sales Manager</span>
                </div>
              </div>
            </li>
            <li
              className="team_list-item col-12 col-md-6 col-xl-4 col-xxl-3"
              data-order={8}
            //   data-aos="fade-up"
            >
              <div className="wrapper d-flex flex-column">
                <div className="team_list-item_img">
                
                  <img
                      className="lazy img"
                      src="img/team/08.jpg"
                      alt="Zachary Wagner"
                    />
                  <div className="overlay d-flex justify-content-center align-items-end">
                    <ul className="overlay_socials d-flex justify-content-center align-items-center">
                      <li className="list-item">
                        <a
                          className="link d-inline-flex justify-content-center align-items-center"
                          href="/"
                          target="_blank"
                          rel="noopener norefferer"
                        >
                          <i className="icon-facebook" />
                        </a>
                      </li>
                      <li className="list-item">
                        <a
                          className="link d-inline-flex justify-content-center align-items-center"
                          href="/"
                          target="_blank"
                          rel="noopener norefferer"
                        >
                          <i className="icon-instagram" />
                        </a>
                      </li>
                      <li className="list-item">
                        <a
                          className="link d-inline-flex justify-content-center align-items-center"
                          href="/"
                          target="_blank"
                          rel="noopener norefferer"
                        >
                          <i className="icon-twitter" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team_list-item_info d-flex flex-column align-items-center">
                  <span className="name">Zachary Wagner</span>{" "}
                  <span className="profession secondary">
                    Social Media Manager
                  </span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default Section6;
