/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { addItem } from "../Redux/Addtochart";
import { addItemInList } from "../Redux/wishlistSlice";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Section1 = () => {
  const [data, setdata] = useState([]);
  const [filetered, setfiltered] = useState([]);
  const disptach = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    fetachdata();
  }, []);
  const fetachdata = async () => {
    await axios
      .get("https://api.weedowl.dev-iuh.xyz/api/products")
      .then((res) => {
        setdata(res?.data?.data);
        fillterData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fillterData = (dataToFilter) => {
    const fillterDatatoprint = dataToFilter.filter((data) => data.is_discounted === true);
    setfiltered(fillterDatatoprint);
  };
  console.log(filetered, data, "fillterData");

  const Addtochart = (data) => {
    console.log(data, "checkout");
    const Datatosend = {
      image: data.media,
      name: data.title,
      price: data.discounted_amount,
      qty: 1,
      id: data.id,
    };
    disptach(addItem(Datatosend));
    toast.success("Added Successfully!");
  };
  const Addtolist = (data) => {
    const Datatosend = {
      image: data.media,
      name: data.title,
      price: data.discounted_amount,
      id: data.id,
    };
    disptach(addItemInList(Datatosend));
    toast.success("Added To List Successfully!");
  };
  const Gotosignalpage = (id) => {
    navigate(`/singleproduct/${id}`);
  };
  return (
    <div className="container">
      <Toaster position="top-right" reverseOrder={false} />
      <div className="row">
        <h1 className="text-center my-2">Weekly Offers Products</h1>
      </div>
      <div className="shop_products d-flex flex-column">
        <ul className="shop_products-list d-sm-flex flex-wrap">
          {filetered?.map((data, index) => {
            return (
              <li
                key={index}
                className="shop_products-list_item col-sm-6 col-md-4 col-lg-6 col-xl-4"
                data-order={index}
              >
                <div className="wrapper d-flex flex-column">
                  <div className="media">
                    <div className="overlay d-flex flex-column align-items-center justify-content-center">
                      <ul className="action d-flex align-items-center justify-content-center">
                        <li className="list-item"></li>
                        <li className="list-item">
                          <div className="action_link d-flex align-items-center justify-content-center">
                            <i
                              className="icon-basket"
                              onClick={() => Addtochart(data)}
                            />
                          </div>
                        </li>
                        <li className="list-item">
                          <div
                            className="action_link d-flex align-items-center justify-content-center"
                            data-role="wishlist"
                          >
                            <i
                              className="icon-heart"
                              onClick={() => Addtolist(data)}
                            />
                          </div>
                        </li>
                        <li className="list-item">
                          <div
                            className="action_link d-flex align-items-center justify-content-center"
                            data-trigger="view"
                          >
                            <i
                              className="icon-eye"
                              onClick={() => Gotosignalpage(data.id)}
                            />
                          </div>
                        </li>
                      </ul>
                    </div>

                    <img
                      className="lazy"
                      src={data.media[0].media}
                      alt="media"
                    />
                  </div>
                  <div className="main d-flex flex-column">
                    <div className="main_rating">
                      <ul className="main_rating-stars d-flex align-items-center accent">
                        <li className="main_rating-stars_star">
                          <i className="icon-star_fill" />
                        </li>
                        <li className="main_rating-stars_star">
                          <i className="icon-star_fill" />
                        </li>
                        <li className="main_rating-stars_star">
                          <i className="icon-star_fill" />
                        </li>
                        <li className="main_rating-stars_star">
                          <i className="icon-star_fill" />
                        </li>
                        <li className="main_rating-stars_star">
                          <i className="icon-star_fill" />
                        </li>
                      </ul>
                    </div>

                    <h4 className="my-2">{data.title}</h4>
                    <div className="main_price">
                      {data.is_discounted ? (
                        <>
                          <span className="price price--old">
                            {data.amount}
                          </span>
                          <span className="price">
                            ${data.discounted_amount}
                          </span>
                        </>
                      ) : (
                        <span className="price">${data.amount}</span>
                      )}
                    </div>

                    <h5 className="mt-3 price">Offer title :</h5>
                    <h4 className="my-2">{data.offer.offer.title}</h4>
                    <div >
                      <span>
                        <b>Start Date :</b>
                      </span>
                      <span>{data.offer.offer.start_date}</span>
                    </div>
                    <div >
                      <span>
                        <b>end Date :</b>
                      </span>
                      <span>{data.offer.offer.end_date}</span>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Section1;
