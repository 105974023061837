/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import PageHeader from "../../Globalcomponent/PageHeader/PageHeader";
import { useParams } from "react-router-dom";
import axios from "axios";
const Newsdetail = () => {
  const { id } = useParams();
  const [data, setdata] = useState([]);
  useEffect(() => {
    fatchdata();
  }, []);

  const fatchdata = async () => {
    await axios
      .get(`https://api.weedowl.dev-iuh.xyz/api/news_letters/${id}`)
      .then((res) => {
        setdata(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <PageHeader
        title="News Detail"
        description="Nibh tellus molestie nunc non blandit. Mi tempus imperdiet nulla malesuada pellentesque elit"
      />

      <div className="container">
        <div className="row">
            <h1 className="text-center">{data.title}</h1>
            <div className="my-3" dangerouslySetInnerHTML={{ __html: data.description }} />
        </div>
      </div>
    </>
  );
};

export default Newsdetail;
