import React from "react";

const Section4 = () => {
  return (
    <>
      <section className="newsletter section">
        <div className="container">
          <div className="wrapper">
            <div className="newsletter_deco">
              <div className="newsletter_deco-wrapper">
              
                <img
                    className="lazy leaf leaf--left"
                    src="img/banners/leaf--left.png"
                    alt="deco"
                  />
              </div>
              <div className="newsletter_deco-wrapper">
                
                <img
                    className="lazy leaf leaf--right"
                    src="img/banners/leaf--right.png"
                    alt="deco"
                  />
              </div>
            </div>
            <div className="newsletter_highlight">
              <span className="underlay underlay--left">
                <span className="underlay_circle underlay_circle--accent" />{" "}
              </span>
              <span className="underlay underlay--right">
                <span className="underlay_circle underlay_circle--small underlay_circle--green" />{" "}
                <span className="underlay_circle underlay_circle--big underlay_circle--green" />
              </span>
            </div>
            <div className="newsletter_content">
              <div className="newsletter_header">
                <h2 className="newsletter_header-title">
                  Sign Up for Our Newsletter
                </h2>
                <p className="newsletter_header-text">
                  Accumsan sit amet nulla facilisi morbi tempus. Suscipit tellus
                  mauris a diam maecenas sed enim ut sem
                </p>
              </div>
              <form
                className="newsletter_form d-sm-flex"
                data-type="newsletter"
                action="#"
                method="post"
              >
                <input
                  className="newsletter_form-field field required"
                  type="text"
                  data-type="email"
                  placeholder="Email"
                />{" "}
                <button className="newsletter_form-btn btn" type="submit">
                  Send
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Section4;
