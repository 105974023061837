import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addItem: (state, action) => {
      const newItem = action.payload;
      const existingItem = state.items.find((item) => item.id === newItem.id);

      if (!existingItem) {
        // If the item doesn't exist, add it to the cart.
        state.items.push(newItem);
      }
    },
    removeItem: (state, action) => {
      const itemId = action.payload;

      state.items = state.items.filter((item) => item.id !== itemId);
    },
    clearaddtochartlist: (state) => {
      state.items = [];
    },
    updateCartItemQuantity: (state, action) => {
      const { id, qty } = action.payload;
      const itemIndex = state.items.findIndex(item => item.id === id);
      if (itemIndex !== -1) {
        state.items[itemIndex].qty = qty;
      }
    }
  },
});

export const { addItem, removeItem, updateCartItemQuantity,clearaddtochartlist } = cartSlice.actions;

export default cartSlice.reducer;
