import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const LatestNews = () => {
  const [news,setnews]=useState([])
  useEffect(()=>{
    fetachdata()
  },[])
  const fetachdata = async()=>{
    await axios
    .get("https://api.weedowl.dev-iuh.xyz/api/news_letters")
    .then((res) => {
      setnews(res?.data?.data)
    })
    .catch((err) => {
      console.log(err);
    });
  }
  return (
    <>
      <section className="latest section">
        <div className="container">
          <h2 className="latest_header">Latest News</h2>
          <div className="latest_wrapper d-flex flex-column-reverse flex-lg-row">
            <div className="latest_promo" 
            >
                <img
                  className="lazy latest_promo-bg"
                  src="img/index/promo.jpg"
                  alt="promo"
                />
             
              <div className="overlay d-flex d-sm-block flex-column justify-content-center">
                <h4 className="overlay_header">
                  Buy Marijuana Seeds Online and Get a Gift
                </h4>
                <p className="overlay_text">
                  Feugiat in fermentum posuere urna nec tincidunt praesent
                  semper feugiat pulvinar proin
                </p>
              </div>
            </div>
            <ul className="latest_list col-lg-7 col-xl-6">
              {
                news.slice(0,3).map((data,index)=>{
                 return(
                  <li
                  className="list-item d-flex flex-column flex-sm-row"
                >
                  <span className="latest_list-preview">
                  <img
                        className="lazy preview-img"
                        src={data.thumbnail}
                        alt="Top 15 Best Strain Of Weed For Anxiety"
                      />
                  
                  </span>
                  <div className="latest_list-main d-sm-flex flex-column justify-content-between flex-grow-1">
                    <span className="metadata">
                      <span className="metadata_item d-inline-flex align-items-center">
                        <i className="icon-calendar_day icon secondary" />
                        {data.created_at}
                      </span>
                     
                    </span>
                    <Link className="title" to={`/new/${data.id}`}>
                      {data.title}
                    </Link>
                    <p className="text">
                      {data.short_description}
                    </p>
                  </div>
                </li>
                 )
                })
              }
              {/* <li
                className="list-item d-flex flex-column flex-sm-row"
              >
                <span className="latest_list-preview">
                <img
                      className="lazy preview-img"
                      src="img/index/latest01.jpg"
                      alt="Top 15 Best Strain Of Weed For Anxiety"
                    />
                
                </span>
                <div className="latest_list-main d-sm-flex flex-column justify-content-between flex-grow-1">
                  <span className="metadata">
                    <span className="metadata_item d-inline-flex align-items-center">
                      <i className="icon-calendar_day icon secondary" />{" "}
                      September 30, 2021
                    </span>
                    <span className="metadata_item d-inline-flex align-items-center">
                      <i className="icon-comments icon secondary" />{" "}
                      <span className="number d-md-none">0</span>{" "}
                      <span className="text d-none d-md-inline">
                        No Comments
                      </span>{" "}
                    </span>
                  </span>
                  <a className="title" href="/">
                    Top 15 Best Strain Of Weed For Anxiety
                  </a>
                  <p className="text">
                    Felis eget velit aliquet sagittis id consectetur. Eleifend
                    donec pretium vulputate sapien nec sagittis
                  </p>
                </div>
              </li> */}
              {/* <li
                className="list-item d-flex flex-column flex-sm-row"
              >
                <span className="latest_list-preview">
                <img
                      className="lazy preview-img"
                      data-src="img/index/latest02.jpg"
                      src="img/index/latest02.jpg"
                      alt="Raw Cannabis Seeds Promote Health"
                    />
                  
                </span>
                <div className="latest_list-main d-sm-flex flex-column justify-content-between flex-grow-1">
                  <span className="metadata">
                    <span className="metadata_item d-inline-flex align-items-center">
                      <i className="icon-calendar_day icon secondary" />{" "}
                      September 30, 2021
                    </span>
                    <span className="metadata_item d-inline-flex align-items-center">
                      <i className="icon-comments icon secondary" />{" "}
                      <span className="number d-md-none">0</span>{" "}
                      <span className="text d-none d-md-inline">
                        No Comments
                      </span>{" "}
                    </span>
                  </span>
                  <a className="title" href="/">
                    Raw Cannabis Seeds Promote Health
                  </a>
                  <p className="text">
                    Felis eget velit aliquet sagittis id consectetur. Eleifend
                    donec pretium vulputate sapien nec sagittis
                  </p>
                </div>
              </li>
              <li
                className="list-item d-flex flex-column flex-sm-row"
              >
                <span className="latest_list-preview">
                <img
                      className="lazy preview-img"
                      data-src="./img/index/latest03.jpg"
                      src="./img/index/latest03.jpg"
                      alt="How Many Autoflowers In a Tent?"
                    />
                
                </span>
                <div className="latest_list-main d-sm-flex flex-column justify-content-between flex-grow-1">
                  <span className="metadata">
                    <span className="metadata_item d-inline-flex align-items-center">
                      <i className="icon-calendar_day icon secondary" />{" "}
                      September 30, 2021
                    </span>
                    <span className="metadata_item d-inline-flex align-items-center">
                      <i className="icon-comments icon secondary" />{" "}
                      <span className="number d-md-none">0</span>{" "}
                      <span className="text d-none d-md-inline">
                        No Comments
                      </span>{" "}
                    </span>
                  </span>
                  <a className="title" href="/">
                    How Many Autoflowers In a Tent?
                  </a>
                  <p className="text">
                    Felis eget velit aliquet sagittis id consectetur. Eleifend
                    donec pretium vulputate sapien nec sagittis
                  </p>
                </div>
              </li> */}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default LatestNews;
