import React from 'react'
import { Link } from 'react-router-dom'

const Section2 = () => {
  return (
    <div className="promo section--nopb mb-4">
    <div className="container d-flex flex-column flex-md-row">
      <div className="promo_banner promo_banner--big" >
        <h3 className="promo_banner-header">
          Browse our selection of CBD-infused goods
        </h3>
        <p className="promo_banner-text">
          Elementum eu facilisis sed odio morbi quis commodo odio. Mauris
          rhoncus aenean vel elit scelerisque mauris pellentesque
        </p>
        <Link className="promo_banner-btn btn" to="/weeklyoffer">
          Shop Now
        </Link>
      </div>
      <div
        className="promo_banner promo_banner--small d-flex flex-column justify-content-end align-items-start"
      >
        <h3 className="promo_banner-header">Weekly Special</h3>
        <Link className="promo_banner-btn btn" to="/weeklyoffer">
          Shop Now
        </Link>
      </div>
    </div>
  </div>
  )
}

export default Section2