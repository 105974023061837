import React from "react";

const Section1 = () => {
  return (
    <>
      <section className="about mt-3">
        <div className="container">
          <div className="about_main d-lg-flex justify-content-between">
            <div className="about_main-content col-lg-6 col-xl-auto">
              <h2 className="about_main-content_header">
                Our knowledgeable team can help you design experiences tailored
                to your needs by accessing one of the flower selections
              </h2>
              <p className="about_main-content_text">
                Elementum eu facilisis sed odio morbi quis commodo odio. Mauris
                rhoncus aenean vel elit scelerisque mauris pellentesque.
                Accumsan sit amet nulla facilisi morbi tempus. Suscipit tellus
                mauris a diam maecenas sed enim ut sem. Turpis egestas maecenas
                pharetra convallis posuere
              </p>
            </div>
            <div className="about_main-media">
            <img
                  className="lazy"
                  src="./img/about/about.jpg"
                  alt="media"
                />
             
            </div>
          </div>
          <ul className="about_numbers d-flex flex-wrap">
            <li
              className="about_numbers-group col-sm-6 col-lg-3"
              data-order={1}
            >
              <div className="wrapper d-flex flex-column align-items-center align-items-sm-start">
                <span
                  className="countNum number d-flex align-items-center secondary"
                  data-suffix="+"
                  data-value={180}
                >
                  0
                </span>
                <p className="number-label">
                  New products to explore. Arcu vitae elementum curabitur vitae
                  nunc sed
                </p>
              </div>
            </li>
            <li
              className="about_numbers-group col-sm-6 col-lg-3"
              data-order={2}
            >
              <div className="wrapper d-flex flex-column align-items-center align-items-sm-start">
                <span
                  className="countNum number d-flex align-items-center secondary"
                  data-suffix="X"
                  data-value={3}
                >
                  0
                </span>
                <p className="number-label">
                  Quis risus sed vulputate odio ut. Arcu vitae elementum
                  curabitur vitae nunc
                </p>
              </div>
            </li>
            <li
              className="about_numbers-group col-sm-6 col-lg-3"
              data-order={3}
            >
              <div className="wrapper d-flex flex-column align-items-center align-items-sm-start">
                <span
                  className="countNum number d-flex align-items-center secondary"
                  data-suffix="%"
                  data-value={100}
                >
                  0
                </span>
                <p className="number-label">
                  Mauris a diam maecenas sed enim ut sem curabitur vitae nunc
                  sed
                </p>
              </div>
            </li>
            <li
              className="about_numbers-group col-sm-6 col-lg-3"
              data-order={4}
            >
              <div className="wrapper d-flex flex-column align-items-center align-items-sm-start">
                <span
                  className="countNum number d-flex align-items-center secondary"
                  data-suffix="K"
                  data-value={11}
                >
                  0
                </span>
                <p className="number-label">
                  Quis risus sed vulputate odio ut. Arcu vitae elementum
                  curabitur vitae nunc
                </p>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default Section1;
