import React from "react";
import PageHeader from "../Globalcomponent/PageHeader/PageHeader";
import Section1 from "../Weeklyoffer/Section1";

const Weeklyoffer = () => {
  return (
    <>
      <PageHeader
        title="Weekly Offers"
        description="Nibh tellus molestie nunc non blandit. Mi tempus imperdiet nulla malesuada pellentesque elit"
      />
      <Section1 />
    </>
  );
};

export default Weeklyoffer;
