import React, { useState } from "react";
import { Link } from "react-router-dom";

const Banner = () => {
 
  const [first, setfirst] = useState(true);
  const [sec, setsec] = useState(false);
  const [three, setthree] = useState(false);
  const firstSlide = () => {
    setsec(false);
    setthree(false);
    setfirst(true);
  };
  const Secondslide = () => {
    setsec(true);
    setthree(false);
    setfirst(false);
  };
  const thredslide = () => {
    setsec(false);
    setthree(true);
    setfirst(false);
  };
  return (
    <section className="hero">
      <div className="container d-xl-flex align-items-start">
        <div className="hero_about col-xl-6">
          <div className="hero_header">
            <h1 className="hero_header-title">
              Highest Quality Marijuana Seeds
            </h1>
            <p className="hero_header-text">
              Habitasse platea dictumst quisque sagittis purus sit. Dignissim
              enim sit amet venenatis. In est ante in nibh mauris cursus mattis.
              Euismod elementum nisi quis eleifend quam adipiscing
            </p>
            <Link className="hero_header-btn btn" to="/shop">
              Our Products
            </Link>
          </div>
          <div className="hero_contacts">
            <p className="hero_contacts-text">
              Our support team is available 24/7 to resolve any product issues
            </p>
            <span className="hero_contacts-data d-flex flex-column flex-sm-row align-items-center">
              <span className="icon d-flex align-items-center justify-content-center">
                <i className="icon-call" />
              </span>
              <span className="d-flex flex-column">
                <span>Phone number</span>{" "}
                <a className="link" href="tel:+1234567890">
                  +1-202-555-0133
                </a>
              </span>
            </span>
          </div>
        </div>
        <div className="hero_promo col-xl-6">
          <div className="hero_promo-underlay" />
          <div className="hero_promo-underlay--highlight" />
          <div className="hero_slider ">
            {first && (
              <div className=" d-flex flex-column flex-md-row justify-content-between justify-content-md-start align-items-md-center swiper-slide">
                <div className="hero_slider-slide_media">
                  <img
                    className="lazy"
                    src="./img/index/hero01.png"
                    alt="media"
                  />
                </div>
                <div className="hero_slider-slide_main">
                  <h2 className="title">Purple Hawaiian Haze</h2>
                  <p className="text">
                    Nisl vel pretium lectus quam id leo in vitae. Pellentesque
                    adipiscing commodo elit at imperdiet
                  </p>
                  <ul className="list">
                    <li className="list-item d-flex align-items-center justify-content-center justify-content-sm-start">
                      <span className="icon d-flex align-items-center justify-content-center">
                        <i className="icon-check" />{" "}
                      </span>
                      Sativa-Dominant
                    </li>
                    <li className="list-item d-flex align-items-center justify-content-center justify-content-sm-start">
                      <span className="icon d-flex align-items-center justify-content-center">
                        <i className="icon-check" />{" "}
                      </span>
                      THC 110.0-160.0mg/g
                    </li>
                    <li className="list-item d-flex align-items-center justify-content-center justify-content-sm-start">
                      <span className="icon d-flex align-items-center justify-content-center">
                        <i className="icon-check" />{" "}
                      </span>
                      CBD 0.0-1.0mg/g
                    </li>
                  </ul>
                  <Link className="btn--underline" to={'/shop'}>
                    About Product
                  </Link>
                </div>
              </div>
            )}

            {sec && (
              <div className=" d-flex flex-column flex-md-row justify-content-between justify-content-md-start align-items-md-center swiper-slide">
                <div className="hero_slider-slide_media">
                 
                    <img
                      className="lazy"
                      src="img/index/hero02.png"
                      alt="media"
                    />
                </div>
                <div className="hero_slider-slide_main">
                  <h2 className="title">Sour Diesel</h2>
                  <p className="text">
                    Pellentesque adipiscing commodo elit at imperdiet. Nisl vel
                    pretium lectus quam id leo in vitae.
                  </p>
                  <ul className="list">
                    <li className="list-item d-flex align-items-center justify-content-center justify-content-sm-start">
                      <span className="icon d-flex align-items-center justify-content-center">
                        <i className="icon-check" />{" "}
                      </span>
                      Sativa-Dominant
                    </li>
                    <li className="list-item d-flex align-items-center justify-content-center justify-content-sm-start">
                      <span className="icon d-flex align-items-center justify-content-center">
                        <i className="icon-check" />{" "}
                      </span>
                      THC 110.0-160.0mg/g
                    </li>
                    <li className="list-item d-flex align-items-center justify-content-center justify-content-sm-start">
                      <span className="icon d-flex align-items-center justify-content-center">
                        <i className="icon-check" />{" "}
                      </span>
                      CBD 0.0-1.0mg/g
                    </li>
                  </ul>
                  <Link className="btn--underline" to={'/shop'}>
                    About Product
                  </Link>
                </div>
              </div>
            )}
            {three && (
              <div className=" d-flex flex-column flex-md-row justify-content-between justify-content-md-start align-items-md-center swiper-slide">
                <div className="hero_slider-slide_media">
                    <img
                      className="lazy"
                      src="img/index/hero03.png"
                      alt="media"
                    />
                </div>
                <div className="hero_slider-slide_main">
                  <h2 className="title">Northern Lights</h2>
                  <p className="text">
                    Pellentesque adipiscing commodo elit at imperdiet. Nisl vel
                    pretium lectus quam id leo in vitae.
                  </p>
                  <ul className="list">
                    <li className="list-item d-flex align-items-center justify-content-center justify-content-sm-start">
                      <span className="icon d-flex align-items-center justify-content-center">
                        <i className="icon-check" />{" "}
                      </span>
                      Sativa-Dominant
                    </li>
                    <li className="list-item d-flex align-items-center justify-content-center justify-content-sm-start">
                      <span className="icon d-flex align-items-center justify-content-center">
                        <i className="icon-check" />{" "}
                      </span>
                      THC 110.0-160.0mg/g
                    </li>
                    <li className="list-item d-flex align-items-center justify-content-center justify-content-sm-start">
                      <span className="icon d-flex align-items-center justify-content-center">
                        <i className="icon-check" />{" "}
                      </span>
                      CBD 0.0-1.0mg/g
                    </li>
                  </ul>
                  <Link className="btn--underline" to={'/shop'}>
                    About Product
                  </Link>
                </div>
              </div>
            )}

            <div className="hero-pagination d-sm-flex flex-column ">
              <ul>
                <li className={first ? 'demo_btn':' '} onClick={firstSlide} style={{cursor:'pointer'}}>
                  01
                </li>
                <li  className={sec ? 'demo_btn':''}onClick={Secondslide} style={{cursor:'pointer'}}>02</li>
                <li className={three ? 'demo_btn':''} onClick={thredslide} style={{cursor:'pointer'}}>03</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
