import React from 'react'

const Section1 = () => {
  return (
    <> <div className="accordion section">
    <div className="container">
      <div className="accordion_component" id="accordionComponent">
        <div className="accordion_component-item">
          <h4
            className="accordion_component-item_header d-flex justify-content-between align-items-center collapsed"
            data-bs-toggle="collapse"
            data-bs-target="#item-1"
          >
            How Long Does It Take to Ship Orders?{" "}
            <span className="wrapper">
              <i className="icon-caret_down icon" />
            </span>
          </h4>
          <div
            id="item-1"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionComponent"
          >
            <div className="accordion_component-item_body">
              Consectetur a erat nam at. Sit amet tellus cras adipiscing enim
              eu turpis egestas pretium. Vel pretium lectus quam id leo in.
              Gravida quis blandit turpis cursus in hac habitasse platea
              dictumst. Elementum pulvinar etiam.
            </div>
          </div>
        </div>
        <div className="accordion_component-item">
          <h4
            className="accordion_component-item_header d-flex justify-content-between align-items-center"
            data-bs-toggle="collapse"
            data-bs-target="#item-2"
          >
            What Are The Different Types Of Marijuana Strains?{" "}
            <span className="wrapper">
              <i className="icon-caret_down icon transform" />
            </span>
          </h4>
          <div
            id="item-2"
            className="accordion-collapse collapse show"
            data-bs-parent="#accordionComponent"
          >
            <div className="accordion_component-item_body">
              Consectetur a erat nam at. Sit amet tellus cras adipiscing enim
              eu turpis egestas pretium. Vel pretium lectus quam id leo in.
              Gravida quis blandit turpis cursus in hac habitasse platea
              dictumst. Elementum pulvinar etiam non quam lacus. Eget nunc
              scelerisque viverra mauris in. Nam at lectus urna duis convallis
              convallis tellus id. Nunc eget lorem dolor sed. In dictum non
              consectetur a erat nam
            </div>
          </div>
        </div>
        <div className="accordion_component-item">
          <h4
            className="accordion_component-item_header d-flex justify-content-between align-items-center collapsed"
            data-bs-toggle="collapse"
            data-bs-target="#item-3"
          >
            What's The Difference Between Grown Cannabis?{" "}
            <span className="wrapper">
              <i className="icon-caret_down icon" />
            </span>
          </h4>
          <div
            id="item-3"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionComponent"
          >
            <div className="accordion_component-item_body">
              Consectetur a erat nam at. Sit amet tellus cras adipiscing enim
              eu turpis egestas pretium. Vel pretium lectus quam id leo in.
              Gravida quis blandit turpis cursus in hac habitasse platea
              dictumst. Elementum pulvinar etiam non quam lacus. Eget nunc
              scelerisque viverra mauris in.
            </div>
          </div>
        </div>
        <div className="accordion_component-item">
          <h4
            className="accordion_component-item_header d-flex justify-content-between align-items-center collapsed"
            data-bs-toggle="collapse"
            data-bs-target="#item-4"
          >
            Are Indoor CBD Seeds Worth Buying?{" "}
            <span className="wrapper">
              <i className="icon-caret_down icon" />
            </span>
          </h4>
          <div
            id="item-4"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionComponent"
          >
            <div className="accordion_component-item_body">
              Consectetur a erat nam at. Sit amet tellus cras adipiscing enim
              eu turpis egestas pretium. Vel pretium lectus quam id leo in.
              Gravida quis blandit turpis cursus in hac habitasse platea
              dictumst. Elementum pulvinar etiam non quam lacus. Eget nunc
              scelerisque viverra mauris in. Elementum pulvinar etiam non quam
              lacus. Eget nunc scelerisque viverra mauris in.
            </div>
          </div>
        </div>
        <div className="accordion_component-item">
          <h4
            className="accordion_component-item_header d-flex justify-content-between align-items-center collapsed"
            data-bs-toggle="collapse"
            data-bs-target="#item-5"
          >
            Is There A Difference Between Medical Cannabis?{" "}
            <span className="wrapper">
              <i className="icon-caret_down icon" />
            </span>
          </h4>
          <div
            id="item-5"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionComponent"
          >
            <div className="accordion_component-item_body">
              Consectetur a erat nam at. Sit amet tellus cras adipiscing enim
              eu turpis egestas pretium. Vel pretium lectus quam id leo in.
              Gravida quis blandit turpis cursus in hac habitasse platea
              dictumst. Elementum pulvinar etiam non quam lacus.
            </div>
          </div>
        </div>
        <div className="accordion_component-item">
          <h4
            className="accordion_component-item_header d-flex justify-content-between align-items-center collapsed"
            data-bs-toggle="collapse"
            data-bs-target="#item-6"
          >
            Best Selling Cannabis Strain?{" "}
            <i className="icon-caret_down icon" />
          </h4>
          <div
            id="item-6"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionComponent"
          >
            <div className="accordion_component-item_body">
              Consectetur a erat nam at. Sit amet tellus cras adipiscing enim
              eu turpis egestas pretium. Vel pretium lectus quam id leo in.
              Gravida quis blandit turpis cursus in hac habitasse platea
              dictumst. Elementum pulvinar etiam non quam lacus. Sit amet
              tellus cras adipiscing enim.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    
    </>
  )
}

export default Section1