/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import Banner from '../Home/Banner'
import Ourknwlege from '../Home/OurKnowlege/Ourknwlege'
import Product from '../Home/Product/Product'
import InfoSection from '../Home/InfoSection/InfoSection'
import HomeSection from '../Home/HomeSection/HomeSection'
import LatestNews from '../Home/Latestnews/LatestNews'
import FeatureProduct from '../Home/FeatureProduct/featureProduct'
import { useDispatch } from 'react-redux'
import { clearWishlist } from '../Redux/wishlistSlice'
import { clearaddtochartlist } from '../Redux/Addtochart'
const Home = () => {
  const location = window.location.href.split("?")[1]
  const dispatch = useDispatch();
  useEffect(() => {
    console.log('Success!',location);
 
    if (location ==='success') {
      console.log('Success!',location);
      dispatch(clearWishlist());
      dispatch(clearaddtochartlist())
    } else {
      console.log('No success parameter found');
    }
  }, []);
  return (
    <>
       <Banner/>
       <FeatureProduct/>
       <Ourknwlege/>
       <Product/>
       <InfoSection/>
       <HomeSection/>
       <LatestNews/>
       </>
  )
}

export default Home