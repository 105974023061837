import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addItem } from "../../Redux/Addtochart";
import { addItemInList } from "../../Redux/wishlistSlice";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import StarRatings from "react-star-ratings";
const FeatureProduct = () => {
  const navigate = useNavigate();
  const disptach = useDispatch();
  const [data, setdata] = useState([]);
  useEffect(() => {
    fetachdata();
  }, []);

  const fetachdata = async () => {
    await axios
      .get("https://api.weedowl.dev-iuh.xyz/api/products")
      .then((res) => {
        setdata(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const Addtochart = (data) => {
    const Datatosend = {
      image: data.media,
      name: data.title,
      price: data.discounted_amount,
      qty: 1,
      id: data.id,
      rating :data.rating,
      thc:data.thc,
      cbd:data.cbd,
      weight:data.weight
    };
    disptach(addItem(Datatosend));
    toast.success("Added Successfully!");
  };
  const Addtolist = (data) => {
    
    const Datatosend = {
      image: data.media,
      name: data.title,
      price: data.discounted_amount,
      id: data.id,
      rating :data.rating,
      thc:data.thc,
      cbd:data.cbd,
      weight:data.weight
    };
    disptach(addItemInList(Datatosend));
    toast.success("Added To List Successfully!");
  };
  const Gotosignalpage = (id) => {
    navigate(`/singleproduct/${id}`);
  };
  return (
    <>
      <section className="featured mt-3">
        <Toaster position="top-right" reverseOrder={false} />
        <div className="container">
          <div className="featured_header">
            <h2 className="featured_header-title">Featured Products</h2>
            <p className="featured_header-text">
              Suscipit tellus mauris a diam maecenas sed enim ut sem. Turpis
              egestas maecenas pharetra convallis posuere
            </p>
          </div>
          <div className="products_list d-grid">
            {data.slice(0, 6).map((data, index) => {
              return (
                <div className="products_list-item">
                  <div className="products_list-item_wrapper d-flex flex-column">
                    <div className="media">
                      <img src={data.media[0].media} alt="sda" />
                      <div className="overlay d-flex justify-content-between align-items-start">
                        <div className="action d-flex flex-column">
                          <div className="action_link d-flex align-items-center justify-content-center">
                            <i
                              className="icon-heart"
                              onClick={() => Addtolist(data)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="main d-flex flex-column align-items-center justify-content-between">
                      <div className="main_rating">
                        <StarRatings
                          rating={data.rating}
                          starRatedColor="#efc368"
                          // changeRating={changeRating}
                          numberOfStars={5}
                          starDimension="30px"
                          name="rating"
                        />
                       
                      </div>
                      <div
                        className="main_title"
                        rel="noopener norefferer"
                        onClick={() => Gotosignalpage(data.id)}
                      >
                        {data.title}
                      </div>
                      <ul className="main_table d-flex flex-column align-items-center">
                        <li className="list-item">
                          <span className="property">THC</span>{" "}
                          <span className="value">{data.thc}%</span>
                        </li>
                        <li className="list-item">
                          <span className="property">CBD</span>{" "}
                          <span className="value">{data.cbd}%</span>
                        </li>
                      </ul>
                      <div className="main_price">
                        <span className="price">${data.discounted_amount}</span>
                      </div>
                      <button
                        className="btn btn--green"
                        onClick={() => Addtochart(data)}
                      >
                        Add to Cart
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}

            <div className="products_list-promo">
              <span className="overlay" />
              <div className="content">
                <h3 className="products_list-promo_title">
                  Best Offers for Customers
                </h3>
                <p className="products_list-promo_text">
                  Shop our selection of vape carts, available in a range of
                  strains, sizes, and formats, including true-to-flower and
                  larger cartridges.
                </p>
                {/* <div className="products_list-promo_timer timer d-flex justify-content-center justify-content-sm-start">
                  <div className="timer_block d-flex flex-column justify-content-center">
                    <span className="timer_block-number" id="days">
                      Shop
                    </span>{" "}
                    days
                  </div>
                  <div className="timer_block d-flex flex-column justify-content-center">
                    <span className="timer_block-number" id="hours">
                      13
                    </span>{" "}
                    hours
                  </div>
                  <div className="timer_block d-flex flex-column justify-content-center">
                    <span className="timer_block-number" id="minutes">
                      18
                    </span>{" "}
                    mins
                  </div>
                  <div className="timer_block d-flex flex-column justify-content-center">
                    <span className="timer_block-number" id="seconds">
                      23
                    </span>{" "}
                    secs
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <Link className="featured_btn btn" to={"/shop"}>
            All Products
          </Link>
        </div>
      </section>
    </>
  );
};

export default FeatureProduct;
