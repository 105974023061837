import axios from "axios";
import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
// import { Link } from "react-router-dom";
const Login = ({ setshowmodal }) => {
  const [showlogin, setshowlogin] = useState(true);
  const [showregister, setshowresgister] = useState(false);
  const [userregister, setuserregister] = useState({
    fname: "",
    lname: "",
    country: "",
    email: "",
    state: "",
    zipcode: "",
    address: "",
    password: "",
    city: "",
  });
  const [userlogin, setuserlogin] = useState({
    email: "",
    password: "",
  });
  const handlelogin = (event) => {
    const { name, value } = event.target;
    setuserlogin((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleregisterget = (event) => {
    const { name, value } = event.target;
    setuserregister((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const pageRegister = () => {
    setshowresgister(true);
    setshowlogin(false);
  };
  const gotologin = () => {
    setshowresgister(false);
    setshowlogin(true);
  };

  const handleRegistersubmit = async () => {
    try {
      const {
        fname,
        lname,
        email,
        password,
        zipcode,
        address,
        state,
        country,
        city,
      } = userregister;
      if (
        fname === "" ||
        lname === "" ||
        email === "" ||
        password === "" ||
        zipcode === "" ||
        address === "" ||
        state === "" ||
        country === "" ||
        city === ""
      ) {
        toast.error("please Fill All the feild");
      } else {
        const fromdata = new FormData();
        fromdata.append("fname", fname);
        fromdata.append("lname", lname);
        fromdata.append("email", email);
        fromdata.append("zipcode", zipcode);
        fromdata.append("address", address);
        fromdata.append("state", state);
        fromdata.append("country", country);
        fromdata.append("city", city);
        fromdata.append("website_id", "1");
        fromdata.append("password", password);
        axios
          .post("https://api.weedowl.dev-iuh.xyz/api/users", fromdata)
          .then((res) => {
            console.log(res, "register");
            if (res.data.status === true) {
              toast.success(res.data.response);
              setuserregister({
                fname: "",
                lname: "",
                country: "",
                email: "",
                state: "",
                zipcode: "",
                address: "",
                password: "",
                city:'',
              });
            }
          })
          .catch((err) => {
            toast.error(err.response.data.message);
            setuserregister({
              fname: "",
              lname: "",
              country: "",
              email: "",
              state: "",
              zipcode: "",
              address: "",
              password: "",
              city:'',
            });
          });
      }
    } catch (error) {
      console.log(error, "registererror");
    }
  };
  const handlesubmitlogin = async () => {
    try {
      const { email, password } = userlogin;
      if (email === "" || password === "") {
        toast.error("please Fill All the feild");
      } else {
        const fromdatatologin = new FormData();
        fromdatatologin.append("email", email);
        fromdatatologin.append("type", "user");
        fromdatatologin.append("password", password);
        axios
          .post("https://api.weedowl.dev-iuh.xyz/api/login", fromdatatologin)
          .then((res) => {
            console.log(res, "Login");
            if (res.data.status === true) {
              toast.success("Login Successfully");
              setuserlogin({
                email: "",
                password: "",
              });
              localStorage.setItem("accesstoken", res.data.data.access_token);
              localStorage.setItem("user",JSON.stringify( res?.data?.data));
              setshowmodal(false);
            }
          })
          .catch((err) => {
            toast.error(err.response.data.message);
            setuserlogin({
              email: "",
              password: "",
            });
          });
      }
    } catch (error) {
      console.log(error, "registererror");
    }
  };
  return (
    <div className="login-modal">
      <Toaster position="top-right" reverseOrder={false} />
      <div className="screen-login col-xl-6 col-lg-8 col-md-10 col-11">
        <div className="icon-custom" onClick={() => setshowmodal(false)}>
          <i className="icon-close custom-close" />
        </div>
        <div className="row justify-content-around mt-4">
          <button
            className={`btn-custoom col-4 col-lg-5 col-md-5 text-light   ${
              showregister ? " " : "bg-light text-dark"
            }`}
            onClick={gotologin}
          >
            Sign in
          </button>
          <button
            className={`btn-custoom col-4 col-lg-5 col-md-5 text-light ${
              showregister && "bg-light text-dark "
            }`}
            onClick={pageRegister}
          >
            Sign-up
          </button>
        </div>
        {showlogin && (
          <div className="row login-from mt-3 justify-content-center  gap-3">
            <div className="row">
              <h3 className="text-light heding text-center">Login To Your Acount</h3>
            </div>
            <div className="col-11 col-lg-8 col-xl-10 col-md-10 ">
              <input
                type="text"
                value={userlogin.email}
                placeholder="Email"
                onChange={handlelogin}
                name="email"
              />
            </div>
            <div className=" col-11 col-lg-8 col-xl-10 col-md-10">
              <input
                type="password"
                value={userlogin.password}
                placeholder="Password"
                onChange={handlelogin}
                name="password"
              />
            </div>
            <button
              className="btn-custoom col-5 text-light mt-3"
              onClick={handlesubmitlogin}
            >
              Login
            </button>
          </div>
        )}
        {showregister && (
          <div className="row login-from mt-3 justify-content-center gap-3">
            <div className="row">
              <h3 className="text-light heding text-center">Register To Login</h3>
            </div>
            <div className="col-11 col-lg-5 col-xl-5 col-md-5">
              <input
                type="text"
                value={userregister.fname}
                placeholder="First Name"
                onChange={handleregisterget}
                name="fname"
              />
            </div>
            <div className="col-11 col-lg-5 col-xl-5 col-md-5">
              <input
                type="text"
                value={userregister.lname}
                placeholder="Last Name"
                onChange={handleregisterget}
                name="lname"
              />
            </div>
            <div className="col-11 col-lg-5 col-xl-5 col-md-5">
              <input
                type="text"
                value={userregister.country}
                placeholder="country"
                onChange={handleregisterget}
                name="country"
              />
            </div>
            <div className="col-11 col-lg-5 col-xl-5 col-md-5">
              <input
                type="text"
                value={userregister.state}
                placeholder="state"
                onChange={handleregisterget}
                name="state"
              />
            </div>
            <div className="col-11 col-lg-5 col-xl-5 col-md-5">
              <input
                type="text"
                value={userregister.city}
                placeholder="city"
                onChange={handleregisterget}
                name="city"
              />
            </div>
            <div className="col-11 col-lg-5 col-xl-5 col-md-5">
              <input
                type="number"
                value={userregister.zipcode}
                placeholder="zipcode"
                onChange={handleregisterget}
                name="zipcode"
              />
            </div>
            <div className="col-11 col-lg-8 col-xl-10 col-md-10">
              <input
                type="text"
                value={userregister.address}
                placeholder="address"
                onChange={handleregisterget}
                name="address"
              />
            </div>
            <div className="col-11 col-lg-8 col-xl-10 col-md-10">
              <input
                type="text"
                value={userregister.email}
                placeholder="Email"
                onChange={handleregisterget}
                name="email"
              />
            </div>
            <div className="col-11 col-lg-8 col-xl-10 col-md-10">
              <input
                type="password"
                value={userregister.password}
                placeholder="Password"
                onChange={handleregisterget}
                name="password"
              />
            </div>

            <button
              className="btn-custoom col-5 text-light mt-3"
              onClick={handleRegistersubmit}
            >
              Register
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
